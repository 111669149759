import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { navigate } from 'gatsby';
import { RadioGroup } from 'components/UI/Radio';
import ModalAddress from 'components/UI/Modal/ModalAddress';
import InputAddress from 'components/UI/Input/InputAddress';

import { useAPI } from 'utils/api';
import { getDispatchData, updateDispatchData } from 'utils/_dispatch.js';
import { md } from 'utils/breakpoints';
import store, { actions, getters } from 'redux/store';
import { useSelector } from 'react-redux';
import { PATH_HOME } from 'utils/constants/paths.js';

import IconClock from 'images/clock.svg';

const elevatorList = [
  { label: '有', value: '1' },
  { label: '無', value: '0' },
];

const Card = styled.div`
  order: 1;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  margin-bottom: 16px;

  @media (max-width: ${md}) {
    border-radius: 0;
    padding: 10px 16px;
    gap: 16px 0;
    margin-bottom: 0;
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
`;

const DeliveryTimeWrapper = styled.div`
  background: #f7f7f7;
  display: flex;
  align-items: center;
  padding: 12px;
`;

const ImgClock = styled.img`
  margin-right: 4px;
`;

const DeliveryTimeText = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-right: auto;
`;

const BtnDeliveryTime = styled.div`
  width: 96px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #214a4c;
  border-radius: 30px;
  background: #5fd2da;
  cursor: pointer;
`;

const FormItemLabel = styled.div`
  color: #3b3516;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;

  .delivery-time {
    font-size: 18px;
    font-weight: 700;
    color: #5fd2da;
  }
`;

const FormItem = styled.div`
  &.delivery-time-set {
    @media (max-width: ${md}) {
      display: none;
    }
  }

  &.delivery-address {
    ${FormItemLabel} {
      @media (max-width: ${md}) {
        display: none;
      }
    }
  }

  &.delivery-time {
    display: none;

    @media (max-width: ${md}) {
      display: block;
    }

    ${FormItemLabel} {
      @media (max-width: ${md}) {
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &.address-nickname {
    display: none;

    @media (max-width: ${md}) {
      display: block;
    }
  }
`;

const SignatureNotice = styled.div`
  color: #333333;
  font-size: 16px;
  font-weight: 400;
`;

const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px 0;
`;

const AddressRow = styled.div`
  display: flex;

  &:nth-child(2) {
    gap: 0 10px;

    @media (max-width: ${md}) {
      display: none;
    }
  }
`;

const AddressInput = styled.input`
  flex: 1;
  background-color: #ffffff;
  border: 1px solid #5fd2da;
  border-radius: 3px;
  padding: 14px 12px;
  outline: none;
  color: #3b3516;
  font-size: 16px;
  font-weight: 400;

  @media (max-width: ${md}) {
    border-radius: 8px;
  }
`;

const InputAddressNickname = styled.input`
  appearance: none;
  outline: none;
  width: 100%;
  height: 50px;
  background-color: #fff;
  border: 1px solid #5fd2da;
  border-radius: 3px;
  padding: 12px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
`;

const CardAddress = ({
  setModalArrangeTime,
  showAddress,
  onModalAddressClose,
  onChangeReceiverAddress,
  checkDataAgain,
  cardAddressCheckFinished,
}) => {
  const api = useAPI();
  const shippingModel = useSelector((state) => getters.getShippingModel(state));
  const homePageOne = useSelector((state) => getters.getHomePageOne(state));
  const deliveryTimeText = useSelector((state) =>
    getters.getDeliveryTimeText(state)
  );
  const [receiveTypeOptions, setReceiveTypeOptions] = useState([]);
  const [elevator, setElevator] = useState('0');
  const [receiveType, setReceiveType] = useState('0');
  const [address, setAddress] = useState('');
  const [storeAddress, setStoreAddress] = useState('');
  const [floor, setFloor] = useState('');
  const [noteDispatch, setNoteDispatch] = useState('');
  const [modalAddress, setModalAddress] = useState(false);
  const [updateModalAddress, setUpdateModalAddress] = useState(false);
  const [rangeTime, setRangeTime] = useState(0);
  const [disableAddress, setDisableAddress] = useState(true);

  const handleChangeElevator = (e) => {
    setElevator(e.target.value);
    updateDispatchData('receiverElevator', e.target.value);
  };

  const handleChangeSignature = (e) => {
    setReceiveType(e.target.value);
    updateDispatchData('receiverReceive', e.target.value);
  };

  const handleChangeNoteDispatch = (e) => {
    setNoteDispatch(e.target.value);
    updateDispatchData('receiverAddressRemark', e.target.value);
  };

  const handleChangeFloor = (e) => {
    setFloor(e.target.value);
    updateDispatchData('floor', e.target.value);
  };

  //取得簽收方式
  const getOptions = () => {
    api
      .getReceiveType()
      .then((res) => {
        const option = [];
        res.forEach((item) => {
          const obj = {
            label: item.name,
            value: item.value,
          };
          option.push(obj);
        });
        setReceiveTypeOptions(option);
        const dispatchData = getDispatchData();
        if (_.get(dispatchData, 'receiverReceive', '0') === '0') {
          setReceiveType(option[0].value);
          updateDispatchData('receiverReceive', option[0].value);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onClose = () => {
    setModalAddress(false);
    const dispatchData = getDispatchData();
    setAddress(_.get(dispatchData, 'receiverAddress', ''));
    setStoreAddress(_.get(dispatchData, 'storeAddress', ''));
    setElevator(_.get(dispatchData, 'receiverElevator', ''));
    setReceiveType(_.get(dispatchData, 'receiverReceive', '0')); //default value
    setFloor(_.get(dispatchData, 'floor', ''));
    setNoteDispatch(_.get(dispatchData, 'receiverAddressRemark', ''));
    setUpdateModalAddress(true);
    if (typeof onModalAddressClose === 'function') onModalAddressClose();
  };

  const onChangeStore = () => {
    store.dispatch(actions.setCartList([]));
    store.dispatch(actions.setQtyStatus(false));
    store.dispatch(actions.setCartPrice({}));
    setTimeout(() => {
      navigate(PATH_HOME, { replace: true });
    }, 200);
  };

  useEffect(() => {
    return () => {
      setUpdateModalAddress(false);
    };
  }, [updateModalAddress]);

  useEffect(() => {
    const dispatchData = getDispatchData();
    setElevator(_.get(dispatchData, 'receiverElevator', ''));
    setReceiveType(_.get(dispatchData, 'receiverReceive', '0')); //default value
    setNoteDispatch(_.get(dispatchData, 'receiverAddressRemark', ''));
    // setAddress(_.get(dispatchData, 'receiverAddress', ''));
    setStoreAddress(_.get(dispatchData, 'storeAddress', ''));
    // setFloor(_.get(dispatchData, 'floor', ''));

    const receiverAddress = _.get(dispatchData, 'receiverAddress', '');

    //比對是否有時間 解決如果該頁刪除再回來時間會不同步
    const shippingDate = _.get(dispatchData, 'shippingDate', '');
    const shippingTimeslot = _.get(dispatchData, 'shippingTimeslot', '');
    const shippingPeriod = _.get(dispatchData, 'shippingPeriod', '');
    const shippingModelLocal = _.get(dispatchData, 'shippingModel', '');
    if (
      shippingDate !== '' &&
      shippingTimeslot !== '' &&
      shippingPeriod !== '' &&
      shippingModelLocal !== ''
    ) {
      //如果第一筆item.shippingPeriod不是-1 代表該分店今日已打烊 改為預約單
      if (shippingPeriod === '-1' && shippingModelLocal === '1') {
        store.dispatch(actions.setDeliveryTimeText(`即刻配送`));
      } else {
        shippingModelLocal === '2'
          ? store.dispatch(
              actions.setDeliveryTimeText(
                `到店取貨 ${shippingDate} ${shippingTimeslot}`
              )
            )
          : store.dispatch(
              actions.setDeliveryTimeText(`${shippingDate} ${shippingTimeslot}`)
            );
      }
    }

    getOptions();
  }, []);

  useEffect(() => {
    console.log('homePageOne', homePageOne);
    setRangeTime(homePageOne?.estimated_fastest_arrival_time);
  }, [homePageOne]);

  useEffect(() => {
    if (checkDataAgain) {
      updateDispatchData('receiverElevator', elevator);
      updateDispatchData('receiverReceive', receiveType);
      updateDispatchData('receiverAddressRemark', noteDispatch);
      updateDispatchData('floor', floor);
      cardAddressCheckFinished();
    }
  }, [checkDataAgain]);

  useEffect(() => {
    const dispatchData = getDispatchData();
    setAddress(_.get(dispatchData, 'receiverAddress', ''));
    setFloor(_.get(dispatchData, 'floor', ''));
    setStoreAddress(_.get(dispatchData, 'storeAddress', ''));
    setNoteDispatch(_.get(dispatchData, 'receiverAddressRemark', ''));
  }, [onChangeReceiverAddress]);

  useEffect(() => {
    if (showAddress) setModalAddress(true);
  }, [showAddress]);

  useEffect(() => {
    if (receiveTypeOptions.length !== 0) {
      setDisableAddress(false);
    } else {
      setDisableAddress(true);
    }
  }, [receiveTypeOptions]);

  return (
    <>
      <Card disabled={disableAddress}>
        <FormItem className="delivery-time-set">
          <DeliveryTimeWrapper>
            <ImgClock src={IconClock} />
            <DeliveryTimeText>{deliveryTimeText}</DeliveryTimeText>
            <BtnDeliveryTime onClick={() => setModalArrangeTime(true)}>
              安排時間
            </BtnDeliveryTime>
          </DeliveryTimeWrapper>
        </FormItem>
        {shippingModel === '1' ? (
          <>
            <FormItem className="delivery-address">
              <FormItemLabel>收貨地址</FormItemLabel>
              <AddressWrapper>
                <AddressRow>
                  <InputAddress
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    onClick={() => setModalAddress(true)}
                  />
                </AddressRow>
                <AddressRow>
                  <AddressInput
                    placeholder="樓層或房號"
                    value={floor}
                    onChange={(e) => handleChangeFloor(e)}
                  />
                  <AddressInput
                    placeholder="公司大樓名稱、其他地址備註"
                    value={noteDispatch}
                    onChange={(e) => handleChangeNoteDispatch(e)}
                  />
                </AddressRow>
              </AddressWrapper>
            </FormItem>
            {homePageOne && (
              <FormItem className="delivery-time">
                <FormItemLabel>
                  外送時間約{' '}
                  <span className="delivery-time">{`${rangeTime}-${
                    rangeTime + 15
                  }分鐘`}</span>
                </FormItemLabel>
              </FormItem>
            )}
            <FormItem className="address-nickname">
              <FormItemLabel>樓層或房號</FormItemLabel>
              <InputAddressNickname
                placeholder="樓層/房號"
                onChange={(e) => handleChangeFloor(e)}
              />
            </FormItem>
            <FormItem className="address-nickname">
              <FormItemLabel>地址備註</FormItemLabel>
              <InputAddressNickname
                placeholder="公司大樓名稱、其他地址備註"
                onChange={(e) => handleChangeNoteDispatch(e)}
              />
            </FormItem>
            <FormItem className="elevator">
              <FormItemLabel>電梯</FormItemLabel>
              <RadioGroup
                name="elevator"
                align="row"
                list={elevatorList}
                value={elevator}
                defaultValue={elevator}
                onChange={handleChangeElevator}
              />
            </FormItem>
            <FormItem className="signature">
              <FormItemLabel>簽收</FormItemLabel>
              <RadioGroup
                name="signature"
                align="vertical"
                list={receiveTypeOptions}
                value={receiveType}
                defaultValue={receiveType}
                onChange={handleChangeSignature}
              />
            </FormItem>
            <SignatureNotice>
              如果您的樓層有安全管制，請通知管理室協助簽收。
            </SignatureNotice>
          </>
        ) : (
          <FormItem className="delivery-address">
            <FormItemLabel>取貨地址</FormItemLabel>
            <AddressWrapper>
              <AddressRow>
                <InputAddress
                  value={storeAddress}
                  onChange={(e) => setStoreAddress(e.target.value)}
                  onClick={() => setModalAddress(true)}
                />
              </AddressRow>
            </AddressWrapper>
          </FormItem>
        )}
      </Card>
      {!updateModalAddress && (
        <ModalAddress
          open={modalAddress}
          onClose={() => onClose()}
          onRefresh={() => onChangeStore()}
        />
      )}
    </>
  );
};

export default CardAddress;
