import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import { Checkbox } from 'components/UI/Checkbox';
import { useAPI } from 'utils/api';
import { navigate } from 'gatsby';
import { getDispatchData } from 'utils/_dispatch.js';
import Product from 'components/Cart/Product';
import MobileProduct from 'components/Cart/MobileProduct';
import RecycleBag from 'components/Cart/RecycleBag';
import MobileRecycleBag from 'components/Cart/MobileRecycleBag';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import { md } from 'utils/breakpoints';
import { setRemoveFromCart } from 'utils/_gtag.js';
import { PATH_HOME } from 'utils/constants/paths.js';

const Card = styled.div`
  order: 3;
  background-color: #fff;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 24px;

  @media (max-width: ${md}) {
    order: 2;
    border-radius: 0;
  }
`;

const CardTitleBar = styled.div`
  padding: 12px 12px 0 24px;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const CardTitleBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const CardAdditionTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #3b3516;
  width: 114px;
  height: 38px;
  padding-top: 8px;

  @media (max-width: ${md}) {
    margin-left: 13px;
  }
`;

const CardAdditionTitleMobile = styled.div`
  margin-left: 13px;
  font-size: 16px;
  font-weight: 700;
  color: #3b3516;
  width: 114px;
  height: 38px;
  padding-top: 8px;
`;

const ItemList = styled.div`
  @media (max-width: ${md}) {
    display: none;
  }
`;

const RecommendBlock = styled.div`
  background-color: #eafeff;
  font-size: 16px;
  font-weight: 400;
  color: #5fd2da;
  padding: 12px 0;
  text-align: center;
`;

const BtnDeleteAll = styled.div`
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 30px;
  width: 114px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #3b3516;
  cursor: pointer;
`;

const MobileItemListTitle = styled.div`
  display: none;

  @media (max-width: ${md}) {
    display: block;
    padding-top: 10px;
    padding-left: 15px;
  }
`;

const MobileItemList = styled.div`
  display: none;
  padding: 0 15px;

  @media (max-width: ${md}) {
    display: flex;
    flex-direction: column;
  }
`;

const FreeShippingBlock = styled.div`
  height: 45px;
  background-color: #fdf8e4;
  font-size: 14px;
  font-weight: 400;
  color: #3b3516;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${md}) {
    display: none;
  }

  > span {
    font-weight: 700;
    display: inline-block;
    margin: 0 3px;
  }
`;

const CardProducts = ({
  getCartFinalStep,
  reload,
  recycleBagMessage,
  type,
  qtyFinished,
}) => {
  const api = useAPI();

  const cartList = useSelector((state) => getters.getCartList(state));
  const cartAdditionItems = useSelector((state) =>
    getters.getCartAdditionItems(state)
  );
  const recycleBag = useSelector((state) => getters.getRecycleBag(state));
  const cartPrice = useSelector((state) => getters.getCartPrice(state));
  const shippingModel = useSelector((state) => getters.getShippingModel(state));
  const additionItems = useSelector((state) => getters.getAdditionItems(state));
  const [checkbox1, setCheckbox1] = useState(false);
  const [showSaleOut, setShowSaleOut] = useState(false);
  const [rmItems, setRmItems] = useState({});
  const [freeShippingAmount, setFreeShippingAmount] = useState('0');

  const checkItems = useRef({});
  const isFinalStep = useRef(false);
  const qtyCheckCountMobile = useRef(0);
  const qtyCheckCountPC = useRef(0);
  const saleOutList = useRef([]);

  const checkItem = (item, isDelete) => {
    if (isDelete) {
      delete checkItems.current[item?.productId];
    } else {
      checkItems.current[item?.productId] = item;
    }
    setRmItems(checkItems.current);
  };

  const removeItem = () => {
    const keys = Object.keys(rmItems);
    const dispatchData = getDispatchData();
    const parameters = {
      productlist: keys,
      storeId: _.get(dispatchData, 'storeId', ''),
    };
    api
      .removeItems(parameters)
      .then((res) => {
        console.log('removeItems', res);
        if (!res) {
          return;
        }
        setRemoveFromCart(cartList, keys);
        setTimeout(() => {
          setRmItems({});
          setCheckbox1(false);
          if (res.productItems.length <= 0) {
            alert('目前購物車沒有商品。', () => {
              navigate(PATH_HOME, { replace: true });
            });
            return;
          }
          if (reload) reload(res, 'delete');
        }, 300);
      })
      .catch((error) => {});
  };

  const qtyCheckFinished = (device) => {
    console.log('device', device);
    const isAddition = type === 'addition';
    const isMobileDevice = isMobile && device === 'mobile';
    const isPCDevice = !isMobile && device === 'pc';

    isPCDevice ? qtyCheckCountPC.current++ : qtyCheckCountMobile.current++;
    if (
      (isPCDevice &&
        qtyCheckCountPC.current === cartList.length &&
        !isFinalStep.current &&
        !isAddition) ||
      (isPCDevice &&
        qtyCheckCountPC.current === cartAdditionItems.length &&
        !isFinalStep.current &&
        isAddition) ||
      (isMobileDevice &&
        qtyCheckCountMobile.current === cartList.length &&
        !isFinalStep.current &&
        !isAddition) ||
      (isMobileDevice &&
        qtyCheckCountMobile.current === cartAdditionItems.length &&
        !isFinalStep.current &&
        isAddition)
    ) {
      isFinalStep.current = true;
      console.log('qtyCheckFinished call back', device);
      qtyFinished(saleOutList.current);
    }
  };

  const saleOutHandle = (itemName) => {
    if (saleOutList.current.length > 0) {
      let isSame = false;
      saleOutList.current.forEach((saleOutName) => {
        if (saleOutName.localeCompare(itemName) == 0) {
          isSame = true;
        }
      });
      if (isSame != true) {
        saleOutList.current.push(itemName);
      }
    } else {
      saleOutList.current.push(itemName);
    }
  };

  useEffect(() => {
    setFreeShippingAmount(
      shippingModel === '1'
        ? _.get(cartPrice, 'shippingMethod.freeShippingAmount', '0')
        : _.get(cartPrice, 'shippingMethod.pickOnStoreAmount', '0')
    );
  }, [cartPrice, shippingModel]);

  return (
    <Card className="">
      {freeShippingAmount !== '0' && freeShippingAmount !== 0 && (
        <FreeShippingBlock>
          尚差 <span>${freeShippingAmount}</span> 以達
          {shippingModel === '1' ? '免運' : '店取門檻'}
        </FreeShippingBlock>
      )}
      <CardTitleBar>
        <CardTitleBlock>
          {type !== 'addition' && (
            <Checkbox
              className="check-all"
              value={checkbox1}
              onChange={(e) => {
                console.log(e.target.checked);
                // alert(e.target.checked);
                setCheckbox1(e.target.checked);
              }}
            >
              全選
            </Checkbox>
          )}
          {type === 'addition' && (
            <CardAdditionTitle>滿額加價購</CardAdditionTitle>
          )}
        </CardTitleBlock>

        {type !== 'addition' && (
          <BtnDeleteAll
            onClick={() => {
              removeItem();
            }}
          >
            刪除
          </BtnDeleteAll>
        )}
      </CardTitleBar>

      {!isMobile && type !== 'addition' ? (
        <ItemList>
          {cartList?.map((item, idx) => {
            return (
              item.productId !== recycleBag?.productId && (
                <Product
                  type={type}
                  item={item}
                  key={`${item.productId}-product-${idx}`}
                  allChecked={checkbox1}
                  checkItem={checkItem}
                  qtyCheckFinished={qtyCheckFinished}
                  reload={reload}
                  saleOutHandle={saleOutHandle}
                />
              )
            );
          })}
          {recycleBag && cartList?.length !== 0 && type !== 'addition' && (
            <RecycleBag
              key={`${recycleBag?.productId}-recycleBag`}
              recycleBag={recycleBag}
              checkItem={checkItem}
              arrFilter={cartList?.filter(
                (item) => item.productId === recycleBag?.productId
              )}
              allChecked={checkbox1}
              qtyCheckFinished={qtyCheckFinished}
              reload={reload}
              saleOutHandle={saleOutHandle}
            />
          )}
        </ItemList>
      ) : (
        !isMobile && (
          <ItemList>
            {cartAdditionItems?.map((item, idx) => {
              return (
                item.productId !== recycleBag?.productId && (
                  <Product
                    type={type}
                    item={item}
                    key={`${item.productId}-product-${idx}`}
                    allChecked={checkbox1}
                    checkItem={checkItem}
                    qtyCheckFinished={qtyCheckFinished}
                    reload={reload}
                    saleOutHandle={saleOutHandle}
                  />
                )
              );
            })}
          </ItemList>
        )
      )}
      {isMobile && type !== 'addition' ? (
        <MobileItemList>
          {cartList?.map((item, idx) => {
            return (
              item.productId !== recycleBag?.productId && (
                <MobileProduct
                  type={type}
                  item={item}
                  key={`${item.productId}-mobile-product-${idx}`}
                  qtyCheckFinished={qtyCheckFinished}
                  saleOutHandle={saleOutHandle}
                  reload={reload}
                />
              )
            );
          })}
          {cartList?.length !== 0 && type !== 'addition' && (
            <MobileRecycleBag
              key={`${recycleBag?.productId}-mobile-recycleBag`}
              recycleBag={recycleBag}
              qtyCheckFinished={qtyCheckFinished}
              reload={reload}
              arrFilter={cartList?.filter(
                (item) => item.productId === recycleBag?.productId
              )}
            />
          )}
        </MobileItemList>
      ) : (
        isMobile && (
          <div>
            {isMobile && <MobileItemListTitle>滿額加價購</MobileItemListTitle>}
            <MobileItemList>
              {cartAdditionItems?.map((item, idx) => {
                return (
                  item.productId !== recycleBag?.productId && (
                    <MobileProduct
                      type={type}
                      item={item}
                      key={`${item.productId}-mobile-addition-product-${idx}`}
                      qtyCheckFinished={qtyCheckFinished}
                      saleOutHandle={saleOutHandle}
                      reload={reload}
                    />
                  )
                );
              })}
            </MobileItemList>
          </div>
        )
      )}

      <RecommendBlock>{`${
        type !== 'addition'
          ? recycleBagMessage
          : '每品限加購1件，且不計入現折/折價券/折扣碼計算門檻 ；未顯示之品項為出貨店已售完'
      }`}</RecommendBlock>
    </Card>
  );
};

export default CardProducts;
