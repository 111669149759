import React from 'react';
import styled from 'styled-components';

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  background-color: #dddddd;
  /* border: 1px solid #dddddd; */
  border-radius: 50px;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &::before {
    content: '';
    position: absolute;
    height: 23px;
    width: 23px;
    left: 1px;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked {
    & + ${Slider} {
      background-color: #5fd2da;

      &::before {
        left: calc(100% - 23px - 1px);
      }
    }
  }
`;

const SwitchDefault = (props) => {
  const { onChange, disabled, checked } = props;

  return (
    <Switch>
      <Checkbox
        type="checkbox"
        onChange={onChange}
        disabled={disabled}
        checked={checked}
      />
      <Slider />
    </Switch>
  );
};

export default SwitchDefault;
