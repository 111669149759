import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import _ from 'lodash';
import { navigate } from 'gatsby';

import { RadioGroup } from 'components/UI/Radio';
import { Checkbox } from 'components/UI/Checkbox';
import PriceSection from 'components/Cart/PriceSection';
import PopupInput from 'components/Cart/PopupInput';
import Modal from 'components/UI/Modal';
import ModalPayment from 'components/Cart/ModalPayment';
import ModalEmailCombine from 'components/Cart/ModalEmailCombine';
import ModalCoupon from 'components/Cart/ModalCoupon';

import SwiperProduct from 'components/UI/Swiper/SwiperProduct';
import AdditionSwiperBlockContent from 'components/Cart/AdditionSwiperBlockContent';

import { useCallbacks } from 'utils/callback';
import {
  PaymentMethodCreditCard,
  PaymentMethodC4Wallet,
  PaymentMethodApplePay,
  PaymentMethodLinePay,
  PaymentMethodJkoPay,
  PaymentMethodPoint,
  PaymentMethodNone,
} from 'utils/constants/paymentMethod.js';
import { useAPI } from 'utils/api';
import {
  checkNaturalNumber,
  checkTaxNumberV2,
  checkCarrier,
} from 'utils/_invoiceCheck.js';
import { updateDispatchData } from 'utils/_dispatch.js';
import { md } from 'utils/breakpoints';

import IconWallet from 'images/c4wallet-2.svg';
import IconMastercard from 'images/i-mastercard.png';
import IconApplePay from 'images/Apple-Pay-Mark.png';
import IconLinepay from 'images/i-linepay.svg';
import IconJko from 'images/jkopay.png';
import IconArrow from 'images/delivery-address-arrow.svg';
import { API_RESPONSE } from 'apis/_const';
import { INVOICE_TYPE } from 'utils/constants/invoiceType.js';
import { PATH_ADDITION } from 'utils/constants/paths.js';

const invoiceList = [
  {
    label: '會員載具',
    value: '0',
    input: false,
    inputPlaceholder: '請輸入會員載具',
    notice: '',
  },
  {
    label: '手機條碼',
    value: '3',
    input: true,
    inputPlaceholder: '請輸入手機條碼',
    notice: '手機條碼格式不正確',
  },
  {
    label: '自然人憑證',
    value: '4',
    input: true,
    inputPlaceholder: '請輸入自然人憑證',
    maxLength: '16',
    notice: '自然人憑證不正確',
  },
  {
    label: '公司用發票(隨貨出)',
    value: '1',
    input: true,
    inputPlaceholder: '請輸入統一編號',
    maxLength: '8',
    notice: '統編格式不正確',
  },
];

const CheckboxPaymentCard = styled(Checkbox)`
  /* gap: 0 16px; */
`;

const Card = styled.div`
  order: 2;
  background-color: #ffffff;
  border-radius: 16px;
  margin-bottom: 16px;

  @media (max-width: ${md}) {
    order: 3;
    border-radius: 0px;
    margin-bottom: 0;
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
`;

const CardTitle = styled.div`
  padding: 16px 12px 12px;
  color: #5fd2da;
  font-size: 18px;
  font-weight: 700;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const CardContentTitle = styled.div`
  color: #333333;
  font-size: 16px;
  font-weight: 700;
  margin-right: auto;
`;

const DiscountWrapper = styled.div`
  padding: 12px;
  background-color: #eafeff;
  cursor: pointer;

  @media (max-width: ${md}) {
    background-color: #fff;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    padding: 12px 0;
    margin: 0 12px;

    ${(props) =>
      props.hasDiscount &&
      css`
        background-color: #d7f4f6;
        padding: 12px;
        margin: 0;
        border-bottom: none;
      `}
  }
`;

const AdditionWrapper = styled.div`
  display: none;

  @media (max-width: ${md}) {
    display: block;
    background-color: #fff;

    border-bottom: 1px solid #e4e4e4;
    padding: 12px 0;
    margin: 0 12px;

    ${(props) =>
      props.hasDiscount &&
      css`
        background-color: #d7f4f6;
        padding: 12px;
        margin: 0;
        border-bottom: none;
      `}
  }
`;

const PaymentWrapper = styled.div`
  padding: 12px;
  background-color: #c9fbff;
  cursor: pointer;

  @media (max-width: ${md}) {
    background-color: #fff;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    padding: 16px 0;
    margin: 0 12px;
  }
`;

const Payment = styled.div`
  margin-top: 4px;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${md}) {
    margin-top: 7px;
  }
`;

const InvoiceWrapper = styled.div`
  padding: 10px 12px 22px;

  @media (max-width: ${md}) {
    padding: 16px 15px 26px;
  }
`;

const RadioGroupInvoice = styled(RadioGroup)`
  margin: 8px 0 22px;

  .input {
    width: 284px;
    height: 44px;
    padding: 10px;
    background-color: #f7f7f7;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }
`;

const TermWrapper = styled.div`
  padding: 22px 0 28px;
  margin: 0 12px;
  border-top: 1px solid #e3e3e3;
`;

const TermTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  margin-bottom: 8px;
`;

const DiscountRow = styled.div`
  display: flex;
  gap: 0 10px;

  &:first-child {
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-bottom: 9px;
  }
`;

const PriceSectionCardPayment = styled(PriceSection)`
  display: none;

  @media (max-width: ${md}) {
    display: flex;
  }
`;

const BtnDiscountDelete = styled.div`
  flex: 0 0 64px;
  width: 64px;
  height: 24px;
  background-color: #5fd2da;
  border-radius: 20px;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
`;

const BtnDiscountEmpty = styled.div`
  width: 64px;
  height: 24px;
  background-color: #00000000;
  border-radius: 20px;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
`;

const DiscountCode = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #333333;
`;

const ModalDiscount = styled(Modal)``;

const SwiperProductWrapper = styled.div`
  margin: 16px 0 0 12px;
  display: none;

  @media (max-width: ${md}) {
    display: block;
  }

  .title {
    font-size: 16px;
  }
`;

const CardPayment = ({
  setReturnContract,
  setAgreePolicy,
  fetchOrder,
  getCartFinalStep,
  checkDataAgain,
  cardPaymentCheckFinished,
}) => {
  const callback = useCallbacks();
  const api = useAPI();
  const payment = useSelector((state) => getters.getPayment(state));
  const paymentUpdate = useSelector((state) => getters.getPaymentUpdate(state));
  const info = useSelector((state) => getters.getInfo(state));
  const cartPrice = useSelector((state) => getters.getCartPrice(state));
  const creditInfo = useSelector((state) => getters.getCreditInfo(state));
  const hkDiscountPrice = useSelector((state) =>
    getters.getHkDiscountPrice(state)
  );
  const [showModalCoupon, setShowModalCoupon] = useState(false);
  const additionItems = useSelector((state) => getters.getAdditionItems(state));
  const additionItemShow = useSelector((state) =>
    getters.getAdditionItemShow(state)
  );

  const [invoice, setInvoice] = useState('0');
  const [invoiceInput, setInvoiceInput] = useState('');
  const [returnContractCheckbox, setReturnContractCheckbox] = useState(true);
  const [agreePolicyCheckbox, setAgreePolicyCheckbox] = useState(true);
  const [discount, setDiscount] = useState('');
  const [modalDiscount, setModalDiscount] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [showNotice, setShowNotice] = useState(false);
  const [isInvoiceTypeDisabled, setIsInvoiceTypeDisabled] = useState(false);
  const [disablePayment, setDisablePayment] = useState(true);
  const [cardNo, setCardNo] = useState('');
  const [isAddCouponFailed, setIsAddCouponFailed] = useState(true);
  const [modalEmail, setModalEmail] = useState(false);
  const [items, setItems] = useState([]);

  const handleClickCoupon = () => {
    setShowModalCoupon(true);
  };

  const handleChangeInvoice = (e) => {
    setInvoice(e.target.value);
    setInvoiceInput('');

    updateDispatchData('invoiceType', e.target.value);
  };

  const handleChangeInvoiceInput = (e) => {
    setInvoiceInput(e.target.value);

    //公司發票
    if (e.target.id === '3') {
      //過濾非數字
      const filterString = e.target.value.replace(/[^0-9]/g, '');
      setInvoiceInput(filterString);

      if (checkTaxNumberV2(filterString)) {
        setShowNotice(false);
      } else {
        setShowNotice(true);
      }

      updateDispatchData('uniformNumbers', filterString);
    }
    //自然人憑證
    if (e.target.id === '2') {
      if (checkNaturalNumber(e.target.value)) {
        setShowNotice(false);
      } else {
        setShowNotice(true);
      }
      updateDispatchData('invoiceVehicle', e.target.value);
    }
    //手機條碼
    if (e.target.id === '1') {
      if (checkCarrier(e.target.value)) {
        setShowNotice(false);
      } else {
        setShowNotice(true);
      }
      updateDispatchData('invoiceVehicle', e.target.value);
    }
  };

  const openModalDiscount = () => {
    setModalDiscount(true);
  };

  const closeModalDiscount = () => {
    setModalDiscount(false);
    setIsAddCouponFailed(true);
  };

  const closeModalCoupon = () => {
    setShowModalCoupon(false);
  };

  const addCoupon = () => {
    api
      .coupons({ code: discount })
      .then((res) => {
        if (res.code === API_RESPONSE.SUCCESS) {
          if (getCartFinalStep) {
            getCartFinalStep();
          }
          closeModalDiscount();
          return;
        } else if (res.code === API_RESPONSE.COUPON_EMAIL_COMBINE) {
          setModalEmail(true);
          setModalDiscount(false);
          return;
        } else {
          setIsAddCouponFailed(false);
          alert(res.reason, () => setIsAddCouponFailed(true));
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeCoupons = () => {
    api
      .removeCoupons()
      .then((res) => {
        if (!res) {
          return;
        }

        if (typeof window !== 'undefined') {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openModalPayment = () => {
    setModalPayment(true);
  };

  const closeModalPayment = () => {
    setModalPayment(false);
  };

  const paymentSelect = (paymentType) => {
    console.log('paymentSelect', paymentType);
  };

  const maskCardNo = (number) => {
    if (number === undefined || number === null || number === '') {
      return '';
    }
    return `${'*'.repeat(number.length - 4)}${number.slice(-4)}`;
  };

  useEffect(() => {
    const clpe = _.get(payment, 'clpe.value', 0);
    setIsInvoiceTypeDisabled(
      payment.payment === PaymentMethodC4Wallet ||
        (payment.payment === PaymentMethodPoint &&
          cartPrice.orderTotal - clpe === 0)
    );

    if (paymentUpdate) {
      const keepPaymentString = localStorage.getItem('KeepPaymentType');
      if (keepPaymentString) {
        const data = JSON.parse(keepPaymentString);
        if (data.payment !== undefined && data.payment !== PaymentMethodNone) {
          store.dispatch(
            actions.setPayment({
              payment: Number.parseInt(data.payment),
            })
          );
          store.dispatch(actions.setPaymentUpdate(false));
        }
      }
    }
  }, [payment]);

  useEffect(() => {
    if (checkDataAgain) {
      console.log('checkDataAgain', checkDataAgain);
      updateDispatchData('invoiceType', invoice);

      if (invoice === INVOICE_TYPE.Tax) {
        updateDispatchData('uniformNumbers', invoiceInput);
      } else if (
        invoice === INVOICE_TYPE.Mobile ||
        invoice === INVOICE_TYPE.CitizenDigital
      ) {
        updateDispatchData('invoiceVehicle', invoiceInput);
      }

      cardPaymentCheckFinished();
    }
  }, [checkDataAgain]);

  useEffect(() => {
    const func = {
      paymentSelect: paymentSelect,
    };
    callback.setCallback(func);
    setAgreePolicy(agreePolicyCheckbox);
    setReturnContract(returnContractCheckbox);
    updateDispatchData('invoiceType', invoice);
    const invoiceData = localStorage.getItem('KeepInvoice');
    if (invoiceData) {
      const data = JSON.parse(invoiceData);
      const { invoiceType, invoiceVehicle, uniformNumbers } = data;
      if (
        invoiceType !== '0' &&
        invoiceType !== undefined &&
        invoiceType !== '' &&
        invoiceType !== null
      ) {
        setInvoice(invoiceType);
        updateDispatchData('invoiceType', invoiceType);
        if (
          invoiceType === INVOICE_TYPE.Mobile ||
          invoiceType === INVOICE_TYPE.CitizenDigital
        ) {
          setInvoiceInput(invoiceVehicle);
        } else if (invoiceType === INVOICE_TYPE.Tax) {
          setInvoiceInput(uniformNumbers);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (
      Object.keys(info).length !== 0 &&
      Object.keys(cartPrice).length !== 0 &&
      hkDiscountPrice
    ) {
      setDisablePayment(false);
    } else {
      setDisablePayment(true);
    }
  }, [cartPrice, info, hkDiscountPrice]);

  useEffect(() => {
    setCardNo(creditInfo.creditCardNumber);
  }, [creditInfo]);

  useEffect(() => {
    if (additionItems.length > 0) {
      const arrItems = additionItems.slice(0, additionItemShow);
      setItems(arrItems);
    }
  }, additionItems);

  return (
    <>
      <Card disabled={disablePayment}>
        <CardTitle>付款設定</CardTitle>
        <DiscountWrapper>
          <DiscountRow onClick={() => handleClickCoupon()}>
            <CardContentTitle>使用折扣碼/折價券</CardContentTitle>
            <img src={IconArrow} />
          </DiscountRow>
          {cartPrice.discountList
            ?.filter((discount) => discount.type === 'coupon')
            .map((item, idx) => {
              return (
                <DiscountRow key={idx}>
                  {/* {idx === 0 && (
                    <BtnDiscountDelete onClick={() => removeCoupons()}>
                      刪除
                    </BtnDiscountDelete>
                  )} */}
                  <DiscountCode>{item.title}</DiscountCode>
                </DiscountRow>
              );
            })}
        </DiscountWrapper>
        {Object.keys(info).length !== 0 && (
          <PriceSectionCardPayment fetchOrder={fetchOrder} />
        )}
        {Object.keys(info).length !== 0 &&
          Object.keys(cartPrice).length !== 0 &&
          hkDiscountPrice && (
            <PaymentWrapper onClick={openModalPayment}>
              <CardContentTitle>付款方式</CardContentTitle>
              <Payment>
                {payment?.payment === PaymentMethodCreditCard ? (
                  <div>
                    <img src={IconMastercard} style={{ width: '20%' }} />
                    <span>
                      {payment.cardInfo
                        ? maskCardNo(payment.cardInfo.cardNo)
                        : cardNo !== ''
                        ? maskCardNo(cardNo)
                        : ''}
                    </span>
                  </div>
                ) : payment?.payment === PaymentMethodC4Wallet ? (
                  <img src={IconWallet} alt="wallet" />
                ) : payment?.payment === PaymentMethodApplePay ? (
                  <img src={IconApplePay} width={50} />
                ) : payment?.payment === PaymentMethodLinePay ? (
                  <img src={IconLinepay} />
                ) : payment?.payment === PaymentMethodJkoPay ? (
                  <img src={IconJko} width={70} />
                ) : payment?.payment === PaymentMethodPoint ? (
                  <div>紅利全額折抵</div>
                ) : (
                  <div>尚未選擇</div>
                )}
                <img src={IconArrow} />
              </Payment>
            </PaymentWrapper>
          )}
        {!isInvoiceTypeDisabled && (
          <InvoiceWrapper>
            <CardContentTitle>發票</CardContentTitle>
            <div>
              <RadioGroupInvoice
                name="invoice"
                inputClassName="input"
                align="vertical"
                list={invoiceList}
                value={invoice}
                defaultValue={invoice}
                inputValue={invoiceInput}
                onChange={handleChangeInvoice}
                onChangeInput={handleChangeInvoiceInput}
                showNotice={showNotice}
                needNotice={true}
              />
            </div>
            <CheckboxPaymentCard
              value={returnContractCheckbox}
              onChange={(e) => {
                setReturnContract(e.target.checked);
                setReturnContractCheckbox(e.target.checked);
              }}
            >
              若本人後續辦理退貨作業,同意由家樂福線上購物代為處理電子發票及銷貨退回證明單,以加速退貨退款作業
            </CheckboxPaymentCard>
          </InvoiceWrapper>
        )}
        <TermWrapper>
          <TermTitle>購物條款</TermTitle>
          <CheckboxPaymentCard
            value={agreePolicyCheckbox}
            onChange={(e) => {
              setAgreePolicy(e.target.checked);
              setAgreePolicyCheckbox(e.target.checked);
            }}
          >
            我已仔細閱讀瞭解家樂福家速配於會員功能項目內載明的隱私權政策、會員服務條碼等所載內容及其意義,並同意遵守現今與未來規範的各種規則。
          </CheckboxPaymentCard>
        </TermWrapper>
        {modalDiscount && (
          <ModalDiscount open={modalDiscount} onClose={closeModalDiscount}>
            <PopupInput
              title="新增折扣碼"
              placeholder="輸入折扣碼"
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              onOk={() => addCoupon()}
              isFailed={isAddCouponFailed}
              couponMessage={cartPrice.couponMessage ?? ''}
            />
          </ModalDiscount>
        )}
        {modalPayment && (
          <ModalPayment
            open={modalPayment}
            onClose={closeModalPayment}
            setModalPayment={setModalPayment}
          />
        )}
        {modalEmail && (
          <ModalEmailCombine
            open={modalEmail}
            onCancel={() => setModalEmail(false)}
            onClose={() => {
              setModalEmail(false);
            }}
            onUpdateFinished={() => {
              setModalEmail(false);
              setShowModalCoupon(true);
            }}
          />
        )}
      </Card>

      <ModalCoupon
        setModalEmail={setModalEmail}
        isOpen={showModalCoupon}
        onCancel={closeModalCoupon}
        getCartFinalStep={getCartFinalStep}
        removeCoupons={removeCoupons}
        discountList={cartPrice?.discountList}
      />
    </>
  );
};

export default CardPayment;
