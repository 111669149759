import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import store, { actions, getters } from 'redux/store';
import Modal from 'components/UI/Modal';
import { useAPI } from 'utils/api';
import { useLoading } from 'utils/loading';
import { API_RESPONSE } from 'apis/_const';

const Btn = styled.div`
  flex: 0 0 129px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 30px;
  cursor: pointer;
`;

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px 0;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  text-align: center;
`;

const NoticeBlock = styled.div`
  background-color: #f8f8f8;
  padding: 8px 12px;
  display: flex;
`;

const StyledIconValidation = styled.img`
  margin-right: 12px;
`;

const NoticeText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  flex-basis: 128px;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 500;
  color: #868686;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 10px;
  width: 100%;
`;

const CancelBtn = styled(Btn)`
  background-color: #c4c4c4;
`;

const OkBtn = styled(Btn)`
  background-color: #5fd2da;
`;

const ModalEmailCombine = ({ onCancel, onClose, onUpdateFinished, open }) => {
  const userInfo = useSelector((state) => getters.getUserInfo(state));
  const [email, setEmail] = useState('');
  const api = useAPI();
  const loading = useLoading();
  const [memberProfile, setMemberProfile] = useState({
    mobile: '',
    name: '',
    birthday: '',
    twid: '',
    email: '',
    cityId: '',
    districtId: '',
    address: '',
    printflag: 0,
    detailflag: 0,
  });

  const onChangeEmail = (e) => {
    const value = e.target.value;
    setEmail(value);
  };

  useEffect(() => {
    console.log('userInfo', userInfo);
    setMemberProfile((state) => ({
      ...state,
      ...userInfo,
      cityId: userInfo.city,
      districtId: userInfo.district,
    }));
  }, []);

  const validEmail = () => {
    if (email === '') {
      return false;
    }
    var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (pattern.test(email)) {
      return true; // 有效的电子邮件地址
    }

    return false; // 无效的电子邮件地址
  };

  const updateMemberProfile = () => {
    if (!validEmail()) {
      alert('請輸入正確的Email格式');
      return;
    }
    const param = {
      name: memberProfile.name,
      birthday: memberProfile.birthday,
      twid: memberProfile.twid,
      email: email,
      cityId: memberProfile.cityId,
      districtId: memberProfile.districtId,
      address: memberProfile.address,
      printflag: memberProfile.printflag,
      detailflag: memberProfile.detailflag,
    };

    console.log('param', param);

    loading.show();
    api
      .updateMemberProfile(param)
      .then((res) => {
        loading.dismiss();
        if (res.code !== API_RESPONSE.SUCCESS) {
          confirm(res.reason, '重試', '取消').then(() => {
            updateMemberProfile();
          });
          return;
        }
        if (onUpdateFinished) {
          onUpdateFinished();
        }
      })
      .catch(() => {
        loading.dismiss();
      });
  };

  return (
    <Modal open={open} width={332} onClose={onClose}>
      <Container>
        <Title>
          請輸入Email以使用
          <br />
          折價券/折扣碼
        </Title>

        <Input
          placeholder="請輸入Email"
          value={email}
          onChange={onChangeEmail}
        />
        <ButtonGroup>
          <CancelBtn onClick={onCancel}>取消</CancelBtn>
          <OkBtn onClick={() => updateMemberProfile()}>送出</OkBtn>
        </ButtonGroup>
      </Container>
    </Modal>
  );
};

export default ModalEmailCombine;
