import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { Navigation } from 'swiper';
import { isMobile } from 'react-device-detect';
import { useAPI } from 'utils/api';
import { Swiper, SwiperSlide } from 'components/UI/Swiper';
import AdditionCardProduct from 'components/UI/Card/AdditionCardProduct';
import { md } from 'utils/breakpoints';
import { getDispatchData } from 'utils/_dispatch.js';
import IconArrowRight from 'images/arrow-right.svg';
import 'swiper/css/grid';
import { navigate } from 'gatsby';
import { PATH_RECOMMEND } from 'utils/constants/paths';

const mixinCardProduct = css`
  height: 291px;

  @media (max-width: ${md}) {
    height: 207px;
  }
`;

const Card = styled.div`
  ${mixinCardProduct};

  width: 206px;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  padding: 18px 12px;
  cursor: pointer;
  color: #5fd2da;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${md}) {
    width: 100%;
  }
`;

const StyledCardProduct = styled(AdditionCardProduct)`
  ${mixinCardProduct};

  width: 100%;

  @media (max-width: ${md}) {
    width: 100%;
    padding: 18px 8px 8px;
  }

  .img-wrapper {
    @media (max-width: ${md}) {
      margin: 0 auto 16px;
    }
  }
`;

const SwiperBlockTitleBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  margin-top: 16px;

  @media (max-width: ${md}) {
    margin-bottom: 8px;
  }
`;

const SwiperBlockTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;

  @media (max-width: ${md}) {
    font-size: 18px;
  }
`;

const SwiperBlockCheckMore = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #1430b3;
  margin-left: auto;

  @media (max-width: ${md}) {
    font-size: 14px;
    margin-right: 16px;
  }
`;

const SLIDES_PER_VIEW = isMobile ? 2.5 : 3.5;

const RecommendSwiperBlockContent = ({ getCartFinalStep }) => {
  const recommendItems = useSelector((state) =>
    getters.getRecommendItems(state)
  );
  const recommendData = useSelector((state) => getters.getRecommendData(state));
  const api = useAPI();
  const [recommends, setRecommends] = useState([]);

  useEffect(() => {
    if (recommendData.rows.length > 0) {
      let arrRecommends = [];
      for (let i = 0; i < recommendData.shows; i++) {
        if (i >= recommendData.rows.length - 1) {
          break;
        }
        arrRecommends.push(recommendData.rows[i]);
      }
      setRecommends(arrRecommends);
    }
  }, [recommendData]);

  return (
    <>
      <SwiperBlockTitleBar>
        <SwiperBlockTitle>推薦商品</SwiperBlockTitle>

        <SwiperBlockCheckMore>
          <Link to={`/recommend`}>看更多</Link>
        </SwiperBlockCheckMore>
      </SwiperBlockTitleBar>

      <Swiper
        modules={[Navigation]}
        navigation
        slidesPerView={SLIDES_PER_VIEW}
        slidesPerGroup={Math.floor(SLIDES_PER_VIEW)}
        spaceBetween={isMobile ? 8 : 4}
      >
        {recommends?.map((product, idx) => {
          return (
            <SwiperSlide key={idx}>
              <StyledCardProduct
                type={'recommend'}
                productData={product}
                onChangeCount={() => {
                  if (getCartFinalStep) {
                    console.log('getCartFinalStep');
                    getCartFinalStep();
                  }
                }}
              />
            </SwiperSlide>
          );
        })}

        <SwiperSlide>
          <Card
            onClick={() => {
              navigate(PATH_RECOMMEND);
            }}
          >
            <img src={IconArrowRight} />
            <div style={{ marginTop: 15 }}>看全部</div>
          </Card>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default RecommendSwiperBlockContent;
