import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { getters } from 'redux/store';
import _ from 'lodash';
import { navigate } from 'gatsby-link';

import { useCallbacks } from 'utils/callback';
import { useAPI } from 'utils/api';
import { getDispatchData } from 'utils/_dispatch.js';
import { PATH_PRODUCT } from 'utils/constants/paths.js';

import IconCounterAdd from 'images/item-counter-add.svg';
import IconCounterSub from 'images/item-counter-sub.svg';
import IconItemRemove from 'images/item-remove.svg';

const ItemWrapper = styled.div`
  display: flex;
  padding: 16px 0;
  border-top: 1px solid #5fd2da;

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
`;

const ItemBlock = styled.div`
  &:nth-child(3) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-left: auto;
  }
`;

const ItemImg = styled.img`
  width: 51px;
  height: 51px;
  margin-right: 14px;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
`;

const ItemTitleWrapper = styled.div`
  width: 250px;
  margin-bottom: 8px;
`;

const ItemMainTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
`;

const ItemSubTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #999;
`;

const ItemCounter = styled.div`
  width: 97px;
  height: 29px;
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 0 11px;
`;

const BtnCounter = styled.div`
  width: 12px;
  height: 12px;
  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const ItemCounterNum = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: #3b3516;
  flex: 1;
  text-align: center;
`;

const ItemRemoveBtn = styled.img`
  width: 14px;
  height: 14px;
`;

const ItemPrice = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #3b3516;
`;

const MobileRecycleBag = ({
  recycleBag,
  arrFilter,
  qtyCheckFinished,
  reload,
}) => {
  const api = useAPI();
  const recycleBagInCart = arrFilter[0];
  const callback = useCallbacks();
  const cartList = useSelector((state) => getters.getCartList(state));
  const [qtyDisable, setQtyDisable] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [qty, setQty] = useState(0);
  const [price, setPrice] = useState(0);
  // const [recycleBagInCart, setRecycleBagInCart] = useState({});

  const deleteItem = () => {
    const dispatchData = getDispatchData();
    api
      .removeCart({
        productId: _.get(recycleBag, 'productId', '0'),
        currentStoreId: _.get(dispatchData, 'storeId', ''),
      })
      .then((res) => {
        setQty(0);
        setPrice(0);
        if (reload) reload(res, 'delete');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setShowDelete(price === 0 ? false : true);
  }, [price]);

  const updateItem = (mode) => {
    var count = 0;
    count = mode === 'increase' ? qty + 1 : qty - 1;

    const maxQuantity = _.get(recycleBagInCart, 'c_maxOrderQuantity', 0);
    if (maxQuantity !== 0) {
      if (count > maxQuantity) {
        alert('已達商品購買上限');
        return;
      }
    }
    const dispatchData = getDispatchData();
    api
      .updateCart({
        productId: _.get(recycleBag, 'productId', '0'),
        quantity: count,
        currentStoreId: _.get(dispatchData, 'storeId', ''),
      })
      .then((res) => {
        if (!res) return;
        if (reload) reload(res, 'update');
        setQty(count);
        const productId = _.get(recycleBag, 'productId', '0');
        res?.productItems.forEach((element) => {
          if (element.productId === productId) {
            setPrice(element.price);
          }
        });
      });
  };

  const increaseQty = () => {
    updateItem('increase');
  };

  const decreaseQty = () => {
    const countQty = qty - 1;
    if (countQty === 0) {
      deleteItem();
      return;
    } else if (countQty > 0) {
      updateItem('decrease');
    } else {
      return;
    }
  };

  const getCartCheckQty = () => {
    const parameters = {
      productlist: [
        {
          productId: _.get(recycleBag, 'productId', 0),
          qty: _.get(recycleBagInCart, 'qty', 0),
        },
      ],
    };
    api
      .getCartCheckQty(parameters)
      .then((res) => {
        res.productItems.forEach((element) => {
          if (element.productId === recycleBag.productId) {
            setQtyDisable(!element.remain);
            if (element.remain != true) {
              saleOutHandle(recycleBag.itemName);
            }
          }
        });
        if (qtyCheckFinished) qtyCheckFinished('mobile');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const goToProductInfo = () => {
    navigate(`${PATH_PRODUCT}?pid=${recycleBag.productId}`);
  };

  useEffect(() => {
    const recycleBagCount = _.get(recycleBagInCart, 'qty', 0);
    setQty(recycleBagCount);
    const price = recycleBag?.price ? recycleBag?.price * recycleBagCount : 0;
    setPrice(price);
    recycleBagCount !== 0 ? getCartCheckQty() : setQtyDisable(false);
    setShowDelete(price === 0 ? false : true);
  }, []);

  useEffect(() => {
    const recycleBagCount = _.get(recycleBagInCart, 'qty', 0);
    setQty(recycleBagCount);
    const price = recycleBag?.price ? recycleBag?.price * recycleBagCount : 0;
    setPrice(price);
    setShowDelete(price === 0 ? false : true);
  }, [cartList]);

  return (
    <ItemWrapper className="plastic-bag" disabled={qtyDisable}>
      <ItemBlock>
        <ItemImg imageUrl={recycleBag?.image} onClick={goToProductInfo} />
      </ItemBlock>

      <ItemBlock>
        <ItemTitleWrapper onClick={goToProductInfo}>
          <ItemMainTitle>{recycleBag?.itemName}</ItemMainTitle>
          <ItemSubTitle>{recycleBag?.itemSpec}</ItemSubTitle>
        </ItemTitleWrapper>

        <ItemCounter>
          <BtnCounter icon={IconCounterSub} onClick={() => decreaseQty()} />
          <ItemCounterNum>{`${qty}`}</ItemCounterNum>
          <BtnCounter icon={IconCounterAdd} onClick={() => increaseQty()} />
        </ItemCounter>
      </ItemBlock>

      <ItemBlock>
        {showDelete && (
          <ItemRemoveBtn src={IconItemRemove} onClick={() => deleteItem()} />
        )}

        <ItemPrice>{`$${price}`}</ItemPrice>
      </ItemBlock>
    </ItemWrapper>
  );
};

export default MobileRecycleBag;
