import React, { useState, useEffect } from 'react';
import store, { actions, getters } from 'redux/store';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import { Link } from 'gatsby';

import Modal from 'components/UI/Modal';

import { useAPI } from 'utils/api';
import { getDispatchData } from 'utils/_dispatch.js';

const Btn = styled.div`
  flex: 1;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 30px;
  cursor: pointer;
`;

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px 0;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  text-align: center;
`;

const PriceBlock = styled.div`
  background-color: #f8f8f8;
  padding: 8px 28px;
  display: flex;
  justify-content: space-between;
`;

const PriceItem = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  line-height: 23px;
`;

const PasswordBlock = styled.div``;

const StyledIconValidation = styled.img`
  margin-right: 12px;
`;

const NoticeText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  flex-basis: 128px;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 500;
  color: #868686;
  margin-bottom: 4px;
`;

const ForgotText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #868686;

  a {
    color: #1e5bc6;
    text-decoration: underline;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 10px;
  width: 100%;
`;

const CancelBtn = styled(Btn)`
  background-color: #c4c4c4;
`;

const OkBtn = styled(Btn)`
  background-color: #5fd2da;
`;

const ModalWallet = ({
  onCancel,
  onClose,
  onOk,
  open,
  walletPassword,
  onFinished,
}) => {
  const api = useAPI();

  const cartPrice = useSelector((state) => getters.getCartPrice(state));
  const payment = useSelector((state) => getters.getPayment(state));
  const userInfo = useSelector((state) => getters.getUserInfo(state));

  const [walletNotOpen, setWalletNotOpen] = useState(false);
  const [showWalletText, setShowWalletText] = useState('');
  const [walletValue, setWalletValue] = useState('');
  const [orderTotal, setOrderTotal] = useState(0);
  const [password, setPassword] = useState('');

  const walletValidCode = (password, callback) => {
    // const skey = sessionStorage.getItem('skey');
    const skey = _.get(userInfo, 'skey', '');
    api
      .walletCodeValid({ pwd: password, skey: skey })
      .then((res) => {
        setWalletValue('');
        if (!res) {
          if (callback) callback(false);
          return;
        }
        walletPassword.current = password;
        if (callback) callback(true);
      })
      .catch((error) => {
        setWalletValue('');
      });
  };

  const onNeedWalletValidCode = () => {
    walletValidCode(password, (finished) => {
      if (!finished) return;
      onFinished();
      if (onOk) onOk();
    });
  };

  const getWallet = () => {
    // const skey = sessionStorage.getItem('skey');
    const skey = _.get(userInfo, 'skey', '');
    if (skey === undefined || skey === null || skey === '') {
      setWalletNotOpen(true);
      setShowWalletText('尚未開通');
      return;
    }
    const dispatchData = getDispatchData();
    const storeId = _.get(dispatchData, 'storeId', '');
    api
      .getCarrefourWallet({
        shop_id: storeId,
        account_id: skey,
      })
      .then((result) => {
        if (result.code !== '200') {
          if (result.code === '400') {
            // if (result.data.res_code === '1002') {
            //   setWalletNotOpen(true);
            // }
            setWalletNotOpen(true);
            setShowWalletText('無法取得錢包資訊');
          } else {
            alert(result.reason);
          }
          return;
        }
        setWalletNotOpen(false);
        setWalletValue(result.data.total_wallet_amt);
      })
      .catch((error) => {
        console.log('get address exception =', error);
      });
  };

  useEffect(() => {
    if (!open) return;
    const clpe = _.get(payment, 'clpe.value', 0);
    setOrderTotal(cartPrice.orderTotal - clpe);
    getWallet();
  }, [open]);

  return (
    <Modal open={open} width={332} onClose={onClose}>
      <Container>
        <Title>家樂福錢包</Title>
        <PriceBlock>
          <PriceItem>
            <div>需支付金額</div>
            <div>{`NT${orderTotal}`}</div>
          </PriceItem>
          <PriceItem>
            <div>目前錢包餘額</div>
            {walletNotOpen ? (
              <div>{showWalletText}</div>
            ) : (
              <div>{`NT${walletValue}`}</div>
            )}
          </PriceItem>
        </PriceBlock>
        <PasswordBlock>
          <Input
            placeholder="請輸入6位數密碼"
            maxLength={6}
            type="password"
            inputMode="numeric"
            disable={walletNotOpen}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <ForgotText>
            忘記密碼需到 <Link to="/">家樂福APP</Link> 進行設定
          </ForgotText>
        </PasswordBlock>
        <ButtonGroup>
          <CancelBtn onClick={onCancel}>其它方式</CancelBtn>
          <OkBtn
            onClick={() => onNeedWalletValidCode()}
            disabled={walletValue < orderTotal || walletNotOpen}
          >
            確認付款
          </OkBtn>
        </ButtonGroup>
      </Container>
    </Modal>
  );
};

export default ModalWallet;
