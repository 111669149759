import React from 'react';
import styled from 'styled-components';

import Modal from 'components/UI/Modal';
import Button from 'components/UI/Button';

const StyledModal = styled(Modal)`
  .content-wrapper {
    width: 80vw;
  }
`;

const Container = styled.div`
  position: relative;
  padding: 16px;
`;

const Title = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
  margin-bottom: 8px;
`;

const List = styled.div`
  margin-bottom: 10px;
`;
const DiscountWrapper = styled.div`
  border-bottom: 1px solid #ededed;
`;

const Discount = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  padding: 8px 0;
`;

const DiscountDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #333333;
`;

const DiscountPrice = styled.div``;

const ButtonWrapper = styled.div`
  height: 52px;
`;

const StyledBtn = styled(Button)`
  width: 100%;
  height: 100%;
`;
const DiscountNameBlock = styled.div`
  margin-bottom: 10px;
  margin-left: 15px;
`;

const DiscountName = styled.div``;

const ModalDiscount = (props) => {
  const { open, onClose, list, fromDetail = false, promotionInfo } = props;

  return (
    <StyledModal open={open} onClose={onClose}>
      <Container>
        <Title>折扣</Title>
        {fromDetail ? (
          <List>
            {promotionInfo.orderPromotion && (
              <DiscountWrapper>
                <Discount>
                  <DiscountDescription>
                    {promotionInfo.orderPromotion?.blockDoc}
                  </DiscountDescription>
                  <DiscountPrice>
                    ${promotionInfo.orderPromotion?.discountAmount}
                  </DiscountPrice>
                </Discount>
                <DiscountNameBlock>
                  {promotionInfo.orderPromotion?.promotionName?.map((name) => {
                    return <DiscountName>{name}</DiscountName>;
                  })}
                </DiscountNameBlock>
              </DiscountWrapper>
            )}
            {promotionInfo.shippingFeePromotion && (
              <DiscountWrapper>
                <Discount>
                  <DiscountDescription>
                    {promotionInfo.shippingFeePromotion?.blockDoc}
                  </DiscountDescription>
                  <DiscountPrice>
                    ${promotionInfo.shippingFeePromotion?.discountAmount}
                  </DiscountPrice>
                </Discount>
                <DiscountNameBlock>
                  {promotionInfo.shippingFeePromotion?.promotionName?.map(
                    (name) => {
                      return <DiscountName>{name}</DiscountName>;
                    }
                  )}
                </DiscountNameBlock>
              </DiscountWrapper>
            )}
          </List>
        ) : (
          <List>
            {list?.map((discount) => {
              return (
                <Discount>
                  <DiscountDescription>{discount.title}</DiscountDescription>
                  <DiscountPrice>-{discount.price}</DiscountPrice>
                </Discount>
              );
            })}
          </List>
        )}

        <ButtonWrapper>
          <StyledBtn onClick={onClose}>確認</StyledBtn>
        </ButtonWrapper>
      </Container>
    </StyledModal>
  );
};

export default ModalDiscount;
