import React, { useEffect, useState, useRef } from 'react';
import CardProducts from 'components/Cart/CardProducts';
import ModalOutOfStock from 'components/UI/Modal/ModalOutOfStock';
import { useSelector } from 'react-redux';
import { PATH_HOME } from 'utils/constants/paths.js';
import store, { actions, getters } from 'redux/store';
import { get } from 'lodash';

const CartProductList = ({ getCartFinalStep, reload, recycleBagMessage }) => {
  const saleOutList = useRef([]);
  const qtyCount = useRef(0);
  const [showSaleOut, setShowSaleOut] = useState(false);
  const cartAdditionItems = useSelector((state) =>
    getters.getCartAdditionItems(state)
  );
  const cartPrice = useSelector((state) => getters.getCartPrice(state));

  const qtyCheckFinished = (salOutProducts) => {
    if (salOutProducts.length > 0) {
      saleOutList.current = [...saleOutList.current, ...salOutProducts];
    }
    qtyCount.current++;
    //如果有加價購 會callback 2次 上面一般商品列表 和 下面加價購商品列表
    const total = cartAdditionItems.length > 0 ? 2 : 1;
    if (qtyCount.current === total) {
      if (saleOutList.current.length > 0) {
        setShowSaleOut(true);
      }
      if (getCartFinalStep) getCartFinalStep();
    }
  };

  const checkProducts = () => {
    saleOutList.current = [];
    setShowSaleOut(false);
    if (cartPrice.productItems <= 0) {
      navigate(PATH_HOME, { replace: true });
      return;
    }
    if (getCartFinalStep) getCartFinalStep();
  };

  return (
    <div>
      <CardProducts
        type={'normal'}
        qtyFinished={qtyCheckFinished}
        reload={reload}
        recycleBagMessage={recycleBagMessage}
      />
      {/*滿額加價購*/}
      {cartAdditionItems.length > 0 && (
        <CardProducts
          type={'addition'}
          qtyFinished={qtyCheckFinished}
          reload={reload}
          recycleBagMessage={recycleBagMessage}
        />
      )}
      <ModalOutOfStock
        open={showSaleOut}
        saleOutList={saleOutList.current}
        onClose={() => {
          checkProducts();
        }}
        onOk={() => {
          checkProducts();
        }}
      ></ModalOutOfStock>
    </div>
  );
};

export default CartProductList;
