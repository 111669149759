import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { navigate } from 'gatsby-link';

import { getDispatchData } from 'utils/_dispatch.js';
import { useCallbacks } from 'utils/callback';
import { useAPI } from 'utils/api';
import { setAddToCart, setRemoveFromCart } from 'utils/_gtag.js';
import { PATH_HOME, PATH_PRODUCT } from 'utils/constants/paths.js';

import IconCounterAdd from 'images/item-counter-add.svg';
import IconCounterSub from 'images/item-counter-sub.svg';
import IconItemRemove from 'images/item-remove.svg';
import Cookies from 'js-cookie';

const ItemWrapper = styled.div`
  display: flex;
  padding: 16px 0;

  &:not(:first-child) {
    border-top: 1px solid #e3e3e3;
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
`;

const ItemBlock = styled.div`
  &:nth-child(3) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-left: auto;
  }
`;

const ItemImg = styled.img`
  width: 51px;
  height: 51px;
  margin-right: 14px;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
`;

const ItemTitleWrapper = styled.div`
  width: 250px;
`;

const ItemMainTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
`;

const ItemSubTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #999;
`;

const ItemCounter = styled.div`
  width: 97px;
  height: 29px;
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 0 11px;
`;

const BtnCounter = styled.div`
  width: 12px;
  height: 12px;
  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const ItemCounterNum = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: #3b3516;
  flex: 1;
  text-align: center;
`;

const ItemRemoveBtn = styled.img`
  width: 14px;
  height: 14px;
`;

const ItemPrice = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #3b3516;
`;

const PromotionLabelList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
`;

const PromotionLabel = styled.div`
  display: inline-block;
  background-color: #fff4bc;
  border-radius: 30px;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 400;
  color: #3b3516;
`;

const ItemOriginalPrice = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #868686;
  text-decoration: line-through;
`;

const ItemPriceBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const MobileProduct = ({
  item,
  qtyCheckFinished,
  reload,
  saleOutHandle,
  type,
}) => {
  const api = useAPI();
  const callback = useCallbacks();
  const isSoldOut = useRef(false);
  const clickTimeout = useRef(null);
  const lastConfirmQty = useRef(0);

  const [qtyDisable, setQtyDisable] = useState(true);
  const [qty, setQty] = useState(0);
  const [price, setPrice] = useState(0);
  const [showItem, setShowItem] = useState(true);

  const deleteItem = () => {
    // 先把商品隱藏
    setShowItem(false);
    const dispatchData = getDispatchData();
    api
      .removeCart({
        productId: item.productId,
        currentStoreId: _.get(dispatchData, 'storeId', ''),
        additionalItem: type === 'addition' ? 1 : 0,
      })
      .then((res) => {
        // const cartRefresh = callback.getCallback('cartRefresh');
        // cartRefresh(res);
        // GTAG: 6. 購物車中移除商品
        setRemoveFromCart(item);

        if (res.productItems.length <= 0) {
          alert('目前購物車沒有商品。', () => {
            navigate(PATH_HOME, { replace: true });
          });
          return;
        }

        if (reload) reload(res, 'delete');
      })
      .catch((error) => {
        // 有問題再開回來
        setShowItem(true);
        console.log(error);
      });
  };

  const updateItem = (mode) => {
    var count = 0;
    count = mode === 'increase' ? qty + 1 : qty - 1;

    if (count > item.c_maxOrderQuantity) {
      alert('已達商品購買上限');
      return;
    }
    const dispatchData = getDispatchData();
    api
      .updateCart({
        productId: item.productId,
        quantity: count,
        currentStoreId: _.get(dispatchData, 'storeId', ''),
      })
      .then((res) => {
        if (!res) return;
        // const cartRefresh = callback.getCallback('cartRefresh');
        // cartRefresh(res);
        if (reload) reload(res, 'update');
        setQty(count);
        res?.productItems.forEach((element) => {
          if (element.productId === item.productId && !element.isAdditionItem) {
            setPrice(element.price);
          }
        });

        if (mode === 'increase') {
          // 取得cookie中attributionToken
          const attributionToken = Cookies.get('attributionToken');
          // GTAG: 加商品購物車數量
          setAddToCart(res, item.productId, item, undefined, attributionToken);
        } else {
          setRemoveFromCart(item);
        }
      });

    /* setQty(count);

    // 清除之前的計時器
    clearTimeout(clickTimeout.current);

    // 延遲1秒後執行
    clickTimeout.current = setTimeout(() => {
      // 更新數量
      updateCartAPI(count, mode);
    }, 1000); // 1秒延遲 */
  };

  /* const updateCartAPI = (count, mode) => {
    const dispatchData = getDispatchData();
    api
      .updateCart({
        productId: item.productId,
        quantity: count,
        currentStoreId: _.get(dispatchData, 'storeId', ''),
      })
      .then((res) => {
        if (!res) {
          setQty(lastConfirmQty.current);
          return;
        }
        // const cartRefresh = callback.getCallback('cartRefresh');
        // cartRefresh(res);
        if (reload) reload(res, 'update');
        setQty(count);
        lastConfirmQty.current = count;
        res?.productItems.forEach((element) => {
          if (element.productId === item.productId) {
            setPrice(element.price);
          }
        });

        if (mode === 'increase') {
          // GTAG: 加商品購物車數量
          setAddToCart(res, item.productId, item);
        } else {
          setRemoveFromCart(item);
        }
      });
  }; */

  const increaseQty = () => {
    updateItem('increase');
  };

  const decreaseQty = () => {
    const countQty = qty - 1;
    if (countQty === 0) {
      deleteItem();
      return;
    } else if (countQty > 0) {
      updateItem('decrease');
    } else {
      return;
    }
  };

  const getCartCheckQty = () => {
    const parameters = {
      productlist: [
        {
          productId: item.productId,
          qty: item.qty,
        },
      ],
    };
    api
      .getCartCheckQty(parameters)
      .then((res) => {
        if (!res) return;
        let data = {};
        for (let i = 0; i < res.productItems.length; i++) {
          const element = res.productItems[i];
          if (element.productId === item.productId) {
            // 同商品如果檢查到第二次處理(加價購商品與一般商品都在購物車時)
            // 同商品在檢查時 加價購商品的remain會返回false
            // remain不是代表缺貨 是不可購買 缺貨是結帳前checkStock那隻api在檢查
            // 以一般商品的remain為主要判斷可不可購買
            if (data.hasOwnProperty(element.productId)) {
              //第二次如果判斷是一般商品 改寫isSoldOut
              if (!element.isbonus) {
                isSoldOut.current = element.remain === true ? false : true;
                break;
              }
            }
            // 把第一次檢查的商品先keep起來
            const tmp = {
              [element.productId]: element.remain,
            };
            Object.assign(data, tmp);
            isSoldOut.current = element.remain === true ? false : true;
          }
        }
        setQtyDisable(isSoldOut.current);
        if (isSoldOut.current) {
          saleOutHandle(item.itemName);
        }
        if (qtyCheckFinished) qtyCheckFinished('mobile');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const goToProductInfo = () => {
    navigate(`${PATH_PRODUCT}?pid=${item.productId}`);
  };

  useEffect(() => {
    lastConfirmQty.current = item.qty;
    setQty(item.qty);
    setPrice(item.price);
    getCartCheckQty();
  }, []);

  // console.log('item', item);

  return showItem ? (
    <ItemWrapper disabled={qtyDisable}>
      <ItemBlock>
        <ItemImg imageUrl={item.image} onClick={goToProductInfo} />
      </ItemBlock>

      <ItemBlock
        style={{ display: 'flex', flexDirection: 'column', gap: '8px 0' }}
      >
        <ItemTitleWrapper onClick={goToProductInfo}>
          <ItemMainTitle>{item.itemName}</ItemMainTitle>

          <ItemSubTitle>{item.itemSpec}</ItemSubTitle>

          {item.productPromotions?.length > 0 && type !== 'addition' && (
            <PromotionLabelList>
              {item.productPromotions?.map((promotion) => {
                if (promotion.calloutMsg === '') return;
                return <PromotionLabel>{promotion.calloutMsg}</PromotionLabel>;
              })}
            </PromotionLabelList>
          )}
        </ItemTitleWrapper>

        <ItemCounter
          style={{
            visibility: `${type !== 'addition' ? 'visible' : 'hidden'}`,
          }}
        >
          <BtnCounter icon={IconCounterSub} onClick={() => decreaseQty()} />

          <ItemCounterNum>{qty}</ItemCounterNum>

          <BtnCounter icon={IconCounterAdd} onClick={() => increaseQty()} />
        </ItemCounter>
      </ItemBlock>

      <ItemBlock>
        <ItemRemoveBtn src={IconItemRemove} onClick={() => deleteItem()} />

        <ItemPriceBlock>
          {item.productPromotions?.length > 0 &&
            item.nonAdjustedPrice > item.price && (
              <ItemOriginalPrice>${item.price * item.qty}</ItemOriginalPrice>
            )}
          <ItemPrice>{`$${price}`}</ItemPrice>
        </ItemPriceBlock>
      </ItemBlock>
    </ItemWrapper>
  ) : (
    <div></div>
  );
};

export default MobileProduct;
