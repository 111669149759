import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import _ from 'lodash';
import { navigate } from 'gatsby-link';

import { Checkbox } from 'components/UI/Checkbox';

import { useAPI } from 'utils/api';
import { getDispatchData } from 'utils/_dispatch.js';
import { PATH_PRODUCT } from 'utils/constants/paths.js';

import IconCounterAdd from 'images/counter-add.svg';
import IconCounterSub from 'images/counter-sub.svg';
import IconProductDelete from 'images/product-delete.svg';

const ProductCheckbox = styled(Checkbox)`
  margin-right: 32px;
`;

const Product = styled.div`
  display: flex;
  align-items: center;
  margin: 0 24px;
  min-height: 135px;

  &:not(:first-child) {
    border-top: 1px solid #e3e3e3;
  }

  &.plastic-bag {
    border-top: 1px solid #5fd2da;

    ${ProductCheckbox} {
      margin-right: 0;
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
`;

const ProductImgWrapper = styled.div`
  flex: 0 0 90px;
  display: flex;
  justify-content: center;
`;

const ProductImg = styled.div`
  width: 64px;
  height: 64px;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
`;

const ProductTitleWrapper = styled.div`
  flex: 0 0 200px;
  padding: 0 15px;
`;

const ProductMainTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #333333;
`;

const ProductSubTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #999999;
`;

const Counter = styled.div`
  display: flex;
  padding: 15px;
`;

const CounterBtn = styled.div`
  width: 24px;
  height: 24px;
  background-color: #5fd2da;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  cursor: pointer;

  &.sub {
    background-image: url(${(props) => props.icon});
  }

  &.add {
    background-image: url(${(props) => props.icon});
  }
`;

const CounterInput = styled.input`
  width: 43px;
  height: 27px;
  border: 1px solid #e3e3e3;
  margin: 0 8px;
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  outline: none;
`;

const ProductPrice = styled.div`
  flex: 0 0 115px;
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  padding-left: 15px;
`;

const DeleteBtn = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-left: auto;
`;

const RecommendText = styled.div`
  flex: 0 0 90px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  word-break: keep-all;
`;

const ProductContentBlock = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  align-self: stretch;
`;

const CheckboxWrapper = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
`;

const DeleteBtnWrapper = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const RecycleBag = ({
  recycleBag,
  allChecked,
  arrFilter,
  qtyCheckFinished,
  reload,
  checkItem,
  saleOutHandle,
}) => {
  const api = useAPI();
  const recycleBagInCart = arrFilter[0];
  const cartList = useSelector((state) => getters.getCartList(state));

  const [checkbox, setCheckbox] = useState(false);
  const [qtyDisable, setQtyDisable] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [qty, setQty] = useState(0);
  const [price, setPrice] = useState(0);

  const deleteItem = () => {
    const dispatchData = getDispatchData();
    api
      .removeCart({
        productId: _.get(recycleBag, 'productId', '0'),
        currentStoreId: _.get(dispatchData, 'storeId', ''),
      })
      .then((res) => {
        setQty(0);
        setPrice(0);
        if (reload) reload(res, 'delete');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setShowDelete(price === 0 ? false : true);
  }, [price]);

  const updateItem = (mode) => {
    var count = 0;
    count = mode === 'increase' ? qty + 1 : qty - 1;

    const maxQuantity = _.get(recycleBagInCart, 'c_maxOrderQuantity', 0);
    if (maxQuantity !== 0) {
      if (count > maxQuantity) {
        alert('已達商品購買上限');
        return;
      }
    }

    const dispatchData = getDispatchData();

    api
      .updateCart({
        productId: _.get(recycleBag, 'productId', '0'),
        quantity: count,
        currentStoreId: _.get(dispatchData, 'storeId', ''),
      })
      .then((res) => {
        if (!res) return;
        if (reload) reload(res, 'update');
        setQty(count);
        const productId = _.get(recycleBag, 'productId', '0');
        res?.productItems.forEach((element) => {
          if (element.productId === productId) {
            setPrice(element.price);
          }
        });
      });
  };

  const increaseQty = () => {
    updateItem('increase');
  };

  const decreaseQty = () => {
    const countQty = qty - 1;
    if (countQty === 0) {
      deleteItem();
      return;
    } else if (countQty > 0) {
      updateItem('decrease');
    } else {
      return;
    }
  };

  const getCartCheckQty = () => {
    const parameters = {
      productlist: [
        {
          productId: _.get(recycleBag, 'productId', 0),
          qty: _.get(recycleBagInCart, 'qty', 0),
        },
      ],
    };
    api
      .getCartCheckQty(parameters)
      .then((res) => {
        res.productItems.forEach((element) => {
          if (element.productId === recycleBag.productId) {
            setQtyDisable(!element.remain);
            if (element.remain != true) {
              saleOutHandle(recycleBag.itemName);
              // deleteItem();
              // alert(element.removereason, () => {
              //   deleteItem();
              // });
            }
          }
        });
        if (qtyCheckFinished) qtyCheckFinished('pc');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickProduct = () => {
    navigate(`${PATH_PRODUCT}?pid=${recycleBag.productId}`);
  };

  useEffect(() => {
    console.log('recycleBag render');
    console.log('recycleBagInCart', recycleBagInCart);
    const recycleBagCount = _.get(recycleBagInCart, 'qty', 0);
    setQty(recycleBagCount);
    const price = recycleBag?.price ? recycleBag?.price * recycleBagCount : 0;
    setPrice(price);
    recycleBagCount !== 0 ? getCartCheckQty() : setQtyDisable(false);
    setShowDelete(price === 0 ? false : true);
  }, []);

  useEffect(() => {
    setCheckbox(allChecked);
  }, [allChecked]);

  useEffect(() => {
    checkItem(recycleBag, !checkbox);
  }, [checkbox]);

  useEffect(() => {
    setCheckbox(allChecked);
    console.log('cartList', cartList);
    const recycleBagCount = _.get(recycleBagInCart, 'qty', 0);
    setQty(recycleBagCount);
    const price = recycleBag?.price ? recycleBag?.price * recycleBagCount : 0;
    setPrice(price);
    setShowDelete(price === 0 ? false : true);
  }, [cartList]);

  console.log('recycleBag', recycleBag);

  return (
    <Product
      className="plastic-bag"
      key={`${recycleBag?.productId}-recycleBag`}
      disabled={qtyDisable}
      onClick={handleClickProduct}
    >
      <CheckboxWrapper onClick={(e) => e.stopPropagation()}>
        {showDelete && (
          <ProductCheckbox
            disabled={!showDelete}
            value={checkbox}
            onChange={(e) => setCheckbox(e.target.checked)}
          />
        )}
      </CheckboxWrapper>

      <RecommendText>建議加購</RecommendText>

      <ProductImgWrapper>
        <ProductImg imageUrl={recycleBag?.image} />
      </ProductImgWrapper>

      <ProductTitleWrapper className="plastic-bag">
        <ProductMainTitle>{recycleBag?.itemName}</ProductMainTitle>

        <ProductSubTitle>{recycleBag?.itemSpec}</ProductSubTitle>
      </ProductTitleWrapper>

      <ProductContentBlock>
        <Counter onClick={(e) => e.stopPropagation()}>
          <CounterBtn
            className="sub"
            icon={IconCounterSub}
            onClick={() => decreaseQty()}
          />

          <CounterInput value={qty} />

          <CounterBtn
            className="add"
            icon={IconCounterAdd}
            onClick={() => increaseQty()}
          />
        </Counter>

        <ProductPrice>{`$${price}`}</ProductPrice>

        {showDelete && (
          <DeleteBtnWrapper onClick={(e) => e.stopPropagation()}>
            <DeleteBtn src={IconProductDelete} onClick={deleteItem} />
          </DeleteBtnWrapper>
        )}
      </ProductContentBlock>
    </Product>
  );
};

export default RecycleBag;
