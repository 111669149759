import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import _, { get } from 'lodash';
import HeaderWrapper from 'components/UI/Header/HeaderWrapper';
import Header from 'components/UI/Header';
import TitleName from 'components/UI/Header/TitleName';
import Footer from 'components/UI/Footer';
import ModalArrangeTime from 'components/UI/Modal/ModalArrangeTime';
import PriceSection from 'components/Cart/PriceSection';
import Auth from 'components/Auth';
import Transaction from 'components/Cart/Transaction';
import CardAddress from 'components/Cart/CardAddress';
import CardPayment from 'components/Cart/CardPayment';
import CardProducts from 'components/Cart/CardProducts';
import CcAuth from 'components/CcAuth';
import { md } from 'utils/breakpoints';
import { useCallbacks } from 'utils/callback';
import { useAPI } from 'utils/api';
import { isAuthenticated } from 'utils/_auth.js';
import { updateDispatchData, getDispatchData } from 'utils/_dispatch.js';
import { setViewCart } from 'utils/_gtag';
import IconClock from 'images/clock.svg';
import { PATH_HOME } from 'utils/constants/paths.js';
import AdditionSwiperBlockContent from 'components/Cart/AdditionSwiperBlockContent';
import RecommendSwiperBlockContent from 'components/Cart/RecommendSwiperBlockContent';
import CartProductList from 'components/Cart/CartProductList';
import { isMobile } from 'react-device-detect';
import { SHIPPING_MODEL } from 'utils/constants/shippingModel.js';

import { logGetRecommendData } from 'utils/_omsLog';

const Container = styled.div`
  width: 1080px;
  padding-top: ${(props) => `${props.headerHeight}px`};
  margin: 0 auto;

  @media (max-width: ${md}) {
    width: 100%;
    margin-bottom: 101px;
  }
`;

const StyledHeader = styled(Header)`
  .btn-cart-wrapper {
    visibility: hidden;
  }

  @media (max-width: ${md}) {
    .btn-cart-wrapper,
    .input-search-wrapper,
    .switch-delivery-wrapper,
    .address-wrapper {
      display: none;
    }
  }
`;

const MobileHeaderTitleWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: -7px;
  transform: translateX(-50%);
  text-align: center;
  display: none;

  @media (max-width: ${md}) {
    display: block;
  }
`;

const HeaderMainTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
`;

const HeaderSubTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: #3b3516;
`;

const DeliveryTimeWrapper = styled.div`
  background: #f2f2f2;
  display: flex;
  align-items: center;
  padding: 12px 15px 12px 28px;
  display: none;

  @media (max-width: ${md}) {
    display: flex;
  }
`;

const ImgClock = styled.img`
  margin-right: 5px;
`;

const DeliveryTimeText = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-right: auto;
`;

const BtnDeliveryTime = styled.div`
  width: 96px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #214a4c;
  border-radius: 30px;
  background: #5fd2da;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 0 16px;

  @media (max-width: ${md}) {
    margin-top: 0;
    display: block;
  }
`;

const PriceSectionCart = styled(PriceSection)`
  position: fixed;
  width: 316px;
  top: ${(props) => props.headerHeight}px;
  left: ${(props) =>
    props.mainSectionRect.left + props.mainSectionRect.width + 24}px;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const MainSection = styled.div`
  width: 730px;

  @media (max-width: ${md}) {
    width: 100%;
  }
`;

const SwiperBlock = styled.div`
  &.category {
    display: none;

    @media (max-width: ${md}) {
      display: block;
    }
  }
`;

const AddtionWrapper = styled.div`
  display: block;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const SwiperProductWrapper = styled.div`
  margin: 16px 0 0 12px;
  display: none;

  @media (max-width: ${md}) {
    display: block;
  }

  .title {
    font-size: 16px;
  }
`;

const Cart = (props) => {
  const api = useAPI();
  const mainSectionRef = useRef();
  const isSentGtagEventRef = useRef(false);
  const shippingModel = useSelector((state) => getters.getShippingModel(state));
  const additionItemShow = useSelector((state) =>
    getters.getAdditionItemShow(state)
  );
  const storeTimeSlots = useSelector((state) =>
    getters.getStoreTimeSlots(state)
  );
  const deliveryTimeText = useSelector((state) =>
    getters.getDeliveryTimeText(state)
  );
  const additionItems = useSelector((state) => getters.getAdditionItems(state));
  const cartAdditionItems = useSelector((state) =>
    getters.getCartAdditionItems(state)
  );
  const recommendItems = useSelector((state) =>
    getters.getRecommendItems(state)
  );
  const [headerHeight, setHeaderHeight] = useState(0);
  const [mainSectionRect, setMainSectionRect] = useState({});
  const [returnContract, setReturnContract] = useState(false);
  const [agreePolicy, setAgreePolicy] = useState(false);
  const [modalArrangeTime, setModalArrangeTime] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [storeName, setStoreName] = useState('');
  const [storeAddress, setStoreAddress] = useState('');
  const [receiverAddress, setReceiverAddress] = useState('');
  const [qcPrime, setQCPrime] = useState('');
  const [startTransaction, setStartTransaction] = useState(false);
  const [ccAuthIsReady, setCcAuthIsReady] = useState(false);
  const [recycleBagMessage, setRecycleBagMessage] = useState('');
  const [checkDataAgain, setCheckDataAgain] = useState(false);
  const [cardPaymentCheckFinished, setCardPaymentCheckFinished] =
    useState(false);
  const [cardAddressCheckFinished, setCardAddressCheckFinished] =
    useState(false);
  const shippingDateRef = useRef('');
  const shippingTimeslotRef = useRef('');
  const shippingPeriodRef = useRef('');
  const fastDeliveryPeriodRef = useRef('');
  const shippingModelRef = useRef('');
  const storeNameRef = useRef('');
  const storeAddressRef = useRef('');
  const receiverAddressRef = useRef('');
  const storeIdRef = useRef('');
  const addressIdRef = useRef('');

  const getAddtionItems = () => {
    //先將資料清空 觸發render
    store.dispatch(actions.setAdditionItems([]));
    store.dispatch(actions.setAdditionItemShow(0));
    const params = {
      shippingModel: 1,
    };
    api
      .getAddtionItems(params)
      .then((res) => {
        store.dispatch(actions.setAdditionItemShow(res.show));
        store.dispatch(actions.setAdditionItems(res.rows));
      })
      .catch((error) => {});
  };

  const getRecommendItems = () => {
    //先將資料清空 觸發render
    store.dispatch(actions.setRecommendItems([]));
    store.dispatch(actions.setRecommendData({}));
    api
      .getRecommendItems()
      .then((res) => {
        store.dispatch(actions.setRecommendItems(res.rows));
        store.dispatch(actions.setRecommendData(res));
        logGetRecommendData(res);
      })
      .catch((error) => {});
  };

  const getCartListNoneCheck = () => {
    store.dispatch(actions.setQtyStatus(false));

    const strDispatch = localStorage.getItem('DispatchData');
    const dispatchData = JSON.parse(strDispatch);
    const model = _.get(dispatchData, 'shippingModel', '');

    api
      .getCartListNoneCheck({ shippingModel: model })
      .then((res) => {
        store.dispatch(actions.setCouponErrorMsg(res.couponMessage));
        store.dispatch(actions.setHkDiscountPrice(res.hkDiscountPrice));
        store.dispatch(
          actions.setPaymentMethods(res.shippingMethod.paymentMethod)
        );
        store.dispatch(
          actions.setCreditInfo(res.shippingMethod.creditCardInfo)
        );
        store.dispatch(actions.setCartPrice(res));
        store.dispatch(actions.setRecycleBag(res.recyclebag));
        setRecycleBagMessage(res.recycleBagMessage);

        //購物車是否為空檢查 垃圾袋不列入計算
        let cartListLength = res.productItems.length;
        const recycleProductId = res.recyclebag.productId;
        const recycleProductIndex = res.productItems.findIndex(
          (element) => element.productId === recycleProductId
        );

        if (recycleProductIndex === -1) {
          //沒有垃圾袋且沒有商品回首頁
          if (cartListLength === 0) {
            navigate(PATH_HOME, { replace: true });
            return;
          }
        } else {
          //有垃圾袋但沒有商品回首頁
          if (cartListLength - 1 === 0) {
            navigate(PATH_HOME, { replace: true });
            return;
          }
        }

        //將商品區分為加價購與一般商品
        const arrAdditionItems = res.productItems.filter(
          (element) => element.isAdditionItem
        );
        const arrNormalItems = res.productItems.filter(
          (element) => !element.isAdditionItem
        );
        store.dispatch(actions.setCartAdditionItems(arrAdditionItems));
        store.dispatch(actions.setCartList(arrNormalItems));

        // GTAG: 點選「購物車按鈕」進入"查看購物車" / 查看購物車
        // isSentGtagEventRef防止不斷重試
        if (!isSentGtagEventRef.current) {
          setViewCart({
            productCount: res.productItems,
            productTotal: res.productTotal,
          });
          isSentGtagEventRef.current = true;
        }
      })
      .catch((error) => {
        // dismissLoading();
      });
  };

  const getCartFinalStep = () => {
    const strDispatch = localStorage.getItem('DispatchData');
    const dispatchData = JSON.parse(strDispatch);
    const model = _.get(dispatchData, 'shippingModel', '');
    //最後再來call 因為如果加價購庫存不足要更新
    getAddtionItems();
    api
      .getCartListFinalStep({ shippingModel: model })
      .then((res) => {
        //當交易時如果購物車被判定沒庫存且沒有商品就回首頁
        if (res.productItems <= 0 && transaction) {
          alert('購物車已無商品', () => {
            navigate(PATH_HOME, { replace: true });
          });
          return;
        }
        store.dispatch(
          actions.setCreditInfo(res.shippingMethod.creditCardInfo)
        );
        store.dispatch(actions.setHkDiscountPrice(res.hkDiscountPrice));
        store.dispatch(actions.setQtyStatus(true));
        store.dispatch(actions.setChangeShippingModel(false));
        store.dispatch(actions.setCartPrice(res));
        store.dispatch(actions.setRecycleBag(res.recyclebag));

        //將商品區分為加價購與一般商品
        const arrAdditionItems = res.productItems.filter(
          (element) => element.isAdditionItem
        );
        const arrNormalItems = res.productItems.filter(
          (element) => !element.isAdditionItem
        );
        store.dispatch(actions.setCartAdditionItems(arrAdditionItems));
        store.dispatch(actions.setCartList(arrNormalItems));
      })
      .catch((error) => {});
  };

  const reload = (res, mode = '') => {
    if (res.recycleBag) {
      // update給的recycleBag b是大寫
      store.dispatch(actions.setRecycleBag(res.recycleBag));
    }
    //重新計算一次價錢 否則紅利上限會有問題
    if (mode === 'update' || mode === 'delete') {
      getRecommendItems();
      getCartFinalStep();
    } else {
      getAddtionItems();
    }
  };

  const getTimeSlots = () => {
    const strDispatch = localStorage.getItem('DispatchData');
    const dispatchData = JSON.parse(strDispatch);
    api
      .getStoreTimeSlots(_.get(dispatchData, 'storeId', ''))
      .then((res) => {
        if (!res) {
          return;
        }

        store.dispatch(actions.setStoreTimeSlots(res));
        if (dispatchData !== null) {
          const shippingPeriod = _.get(dispatchData, 'shippingPeriod', '');
          //如果是-1 代表立即外送 否則是預約單
          if (shippingPeriod === '-1') {
            //立即外送取得timeslot第一筆
            let item = res[0];
            shippingDateRef.current = item.shippingDate;
            shippingTimeslotRef.current = item.shippingTimeslot;
            shippingPeriodRef.current = item.shippingPeriod; //如果原本是-1 但第一筆不是-1 代表時間已經過了只能變成預約單
            fastDeliveryPeriodRef.current = item.fastDeliveryPeriod;

            //立即外送
            updateDispatchData('shippingDate', shippingDateRef.current);
            updateDispatchData('shippingPeriod', shippingPeriodRef.current);
            updateDispatchData('shippingTimeslot', shippingTimeslotRef.current);
            updateDispatchData(
              'fastDeliveryPeriod',
              fastDeliveryPeriodRef.current
            );

            if (
              shippingPeriod === '-1' &&
              shippingModel === SHIPPING_MODEL.Delivery
            ) {
              store.dispatch(actions.setDeliveryTimeText(`即刻配送`));
            } else {
              shippingModel === SHIPPING_MODEL.TakeOut
                ? store.dispatch(
                    actions.setDeliveryTimeText(
                      `到店取貨 ${shippingDateRef.current} ${shippingTimeslotRef.current}`
                    )
                  )
                : store.dispatch(
                    actions.setDeliveryTimeText(
                      `${shippingDateRef.current} ${shippingTimeslotRef.current}`
                    )
                  );
            }
          } else {
            let item = null;
            //比對找尋相同的時間
            res.forEach((element) => {
              if (element.shippingPeriod === time) {
                item = element;
              }
            });

            if (item === null) {
              shippingPeriodRef.current = '-1';
              updateDispatchData('shippingPeriod', '-1');
              getTimeSlots();
              return;
            }

            shippingDateRef.current = item.shippingDate;
            shippingPeriodRef.current = item.shippingPeriod;
            shippingTimeslotRef.current = item.shippingTimeslot;
            fastDeliveryPeriodRef.current = item.fastDeliveryPeriod;

            updateDispatchData('shippingDate', shippingDateRef.current);
            updateDispatchData('shippingPeriod', shippingPeriodRef.current);
            updateDispatchData('shippingTimeslot', shippingTimeslotRef.current);
            updateDispatchData(
              'fastDeliveryPeriod',
              fastDeliveryPeriodRef.current
            );

            if (shippingModel === SHIPPING_MODEL.TakeOut) {
              store.dispatch(
                actions.setDeliveryTimeText(
                  `到店取貨 ${shippingDateRef.current} ${shippingTimeslotRef.current}`
                )
              );
            } else {
              store.dispatch(
                actions.setDeliveryTimeText(
                  `${shippingDateRef.current} ${shippingTimeslotRef.current}`
                )
              );
            }
          }
        }
      })
      .catch((error) => {
        console.log('get address exception =', error);
      });
  };

  const showDispatchData = () => {
    const dispatchData = getDispatchData();
    storeNameRef.current = _.get(dispatchData, 'storeName', '');
    storeAddressRef.current = _.get(dispatchData, 'storeAddress', '');
    receiverAddressRef.current = _.get(dispatchData, 'receiverAddress', '');
    storeIdRef.current = _.get(dispatchData, 'storeId', '');
    shippingModelRef.current = _.get(dispatchData, 'shippingModel', '');
    addressIdRef.current = _.get(dispatchData, 'addressId', '');
    setStoreName(storeNameRef.current);
    setStoreAddress(storeAddressRef.current);
    setReceiverAddress(receiverAddressRef.current);

    //如果沒有配送資料 讓他去填資料並default為外送與即刻配送
    if (dispatchData === null) {
      shippingPeriodRef.current = '-1';
      shippingModelRef.current = SHIPPING_MODEL.Delivery;
      updateDispatchData('shippingPeriod', shippingPeriodRef.current);
      updateDispatchData('shippingModel', shippingModelRef.current);
      setShowAddress(true);
    }
  };

  const onModalAddressClose = () => {
    // window.location.reload();
    showDispatchData();
  };

  const fetchOrder = (QCPrime) => {
    if (QCPrime) setQCPrime(QCPrime);
    setTransaction(true);
    console.log('fetchOrder');
    // 直接下訂單前 先再次取得一次購物車頁面資料
    // 避免使用者分頁 修改到local資料
    setCheckDataAgain(true);
    // setStartTransaction(true); 解開可直接執行交易
  };

  // 同步頁面上的資料 避免使用者開分頁時local資料被修改
  useEffect(() => {
    if (
      cardAddressCheckFinished === true &&
      cardPaymentCheckFinished === true
    ) {
      updateDispatchData('shippingModel', shippingModelRef.current);
      updateDispatchData('storeName', storeNameRef.current);
      updateDispatchData('storeAddress', storeAddressRef.current);
      updateDispatchData('receiverAddress', receiverAddressRef.current);
      updateDispatchData('storeId', storeIdRef.current);
      updateDispatchData('addressId', addressIdRef.current);

      // 400ms後開始交易 確保比較效能比較差的裝置可以同步完畢
      setTimeout(() => {
        setStartTransaction(true);
      }, 400);
    }
  }, [cardPaymentCheckFinished, cardAddressCheckFinished]);

  const onChangeStore = () => {
    store.dispatch(actions.setCartList([]));
    store.dispatch(actions.setQtyStatus(false));
    store.dispatch(actions.setCartPrice({}));

    setTimeout(() => {
      navigate(PATH_HOME, { replace: true });
    }, 200);
  };

  useEffect(() => {
    if (!ccAuthIsReady) return;

    getRecommendItems();
    getCartListNoneCheck();
    getTimeSlots();
    showDispatchData();
    // mappingAddress();
  }, [ccAuthIsReady]);

  useEffect(() => {
    const dispatchData = getDispatchData();
    if (storeTimeSlots.length === 0) return;

    shippingDateRef.current = _.get(dispatchData, 'shippingDate', '');
    shippingTimeslotRef.current = _.get(dispatchData, 'shippingTimeslot', '');
    shippingModelRef.current = shippingModel;

    let delivertyText = '';
    if (shippingModel === SHIPPING_MODEL.TakeOut) {
      delivertyText = `到店取貨 ${shippingDateRef.current} ${shippingTimeslotRef.current}`;
    } else {
      delivertyText =
        _.get(dispatchData, 'shippingPeriod', '') === '-1'
          ? '即刻配送'
          : `${shippingDateRef.current} ${shippingTimeslotRef.current}`;
    }

    store.dispatch(actions.setDeliveryTimeText(delivertyText));
  }, [shippingModel]);

  // 用來設定 PriceSection 的位置
  useEffect(() => {
    if (!mainSectionRef?.current) return;
    setMainSectionRect(mainSectionRef?.current.getBoundingClientRect());

    const resetMainSectionRect = () => {
      setMainSectionRect(mainSectionRef?.current.getBoundingClientRect());
    };
    window.addEventListener('resize', resetMainSectionRect);

    return () => {
      window.removeEventListener('resize', resetMainSectionRect);
    };
  }, [mainSectionRef.current]);

  return (
    <>
      <TitleName />
      <CcAuth onFinished={() => setCcAuthIsReady(true)} />
      {ccAuthIsReady && (
        <div>
          {isAuthenticated() ? (
            <div>
              <Container headerHeight={headerHeight}>
                <HeaderWrapper getHeight={(height) => setHeaderHeight(height)}>
                  <StyledHeader
                    onRefresh={() => onChangeStore()}
                    updateReceiverAddress={receiverAddress}
                    updateStoreAddress={storeAddress}
                    updateStoreName={storeName}
                    onModalClose={() => {
                      onModalAddressClose();
                    }}
                    onChangeShippingMethod={() => {
                      getCartFinalStep();
                    }}
                  >
                    <MobileHeaderTitleWrapper>
                      <HeaderMainTitle>購物車</HeaderMainTitle>
                      <HeaderSubTitle>{storeName}</HeaderSubTitle>
                    </MobileHeaderTitleWrapper>
                  </StyledHeader>
                </HeaderWrapper>
                <DeliveryTimeWrapper>
                  <ImgClock src={IconClock} />
                  <DeliveryTimeText>{deliveryTimeText}</DeliveryTimeText>
                  <BtnDeliveryTime
                    onClick={() => {
                      setModalArrangeTime(true);
                    }}
                  >
                    安排時間
                  </BtnDeliveryTime>
                </DeliveryTimeWrapper>
                <ContentWrapper>
                  <MainSection ref={mainSectionRef}>
                    <CardAddress
                      checkDataAgain={checkDataAgain}
                      cardAddressCheckFinished={() => {
                        setCardAddressCheckFinished(true);
                      }}
                      setModalArrangeTime={setModalArrangeTime}
                      showAddress={showAddress}
                      onModalAddressClose={onModalAddressClose}
                      onChangeReceiverAddress={receiverAddress}
                    />
                    <CardPayment
                      checkDataAgain={checkDataAgain}
                      cardPaymentCheckFinished={() => {
                        setCardPaymentCheckFinished(true);
                      }}
                      setReturnContract={setReturnContract}
                      setAgreePolicy={setAgreePolicy}
                      fetchOrder={fetchOrder}
                      reload={reload}
                      getCartFinalStep={getCartFinalStep}
                    />
                    <CartProductList
                      getCartFinalStep={getCartFinalStep}
                      reload={reload}
                      recycleBagMessage={recycleBagMessage}
                    ></CartProductList>
                    {!isMobile && recommendItems.length > 0 && (
                      <AddtionWrapper>
                        <SwiperBlock>
                          <RecommendSwiperBlockContent
                            getCartFinalStep={getCartFinalStep}
                          />
                        </SwiperBlock>
                      </AddtionWrapper>
                    )}
                    {!isMobile && additionItems.length > 0 && (
                      <AddtionWrapper>
                        <SwiperBlock>
                          <AdditionSwiperBlockContent
                            getCartFinalStep={getCartFinalStep}
                          />
                        </SwiperBlock>
                      </AddtionWrapper>
                    )}
                    {isMobile && recommendItems.length > 0 && (
                      <SwiperProductWrapper>
                        <RecommendSwiperBlockContent
                          getCartFinalStep={getCartFinalStep}
                        />
                      </SwiperProductWrapper>
                    )}
                    {isMobile && additionItems.length > 0 && (
                      <SwiperProductWrapper>
                        <AdditionSwiperBlockContent
                          getCartFinalStep={getCartFinalStep}
                        />
                      </SwiperProductWrapper>
                    )}
                  </MainSection>
                  <PriceSectionCart
                    fetchOrder={fetchOrder}
                    headerHeight={headerHeight}
                    mainSectionRect={mainSectionRect}
                  />
                </ContentWrapper>
              </Container>
              <Footer />
            </div>
          ) : (
            <Auth />
          )}
          {
            <ModalArrangeTime
              open={modalArrangeTime}
              onClose={() => {
                setModalArrangeTime(false);
              }}
            />
          }
          {transaction && (
            <Transaction
              setStartTransction={setStartTransaction}
              startTransction={startTransaction}
              onFinished={() => setTransaction(false)}
              onFailed={() => {
                // 如果交易過程有失敗 要將以下參數恢復到一開始的預設值
                // 以便使用者修改後再次點擊觸發交易
                setCardAddressCheckFinished(false);
                setCardPaymentCheckFinished(false);
                setCheckDataAgain(false);
                setStartTransaction(false);
                setTransaction(false);
              }}
              returnContract={returnContract}
              agreePolicy={agreePolicy}
              qcPrime={qcPrime}
              getCartFinalStep={getCartFinalStep}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Cart;
