import React, { useEffect, useState, useRef } from 'react';
import { useAPI } from 'utils/api';
import { useLoading } from 'utils/loading';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import { navigate } from 'gatsby';
import Cookies from 'js-cookie';
import {
  PaymentMethodNone,
  PaymentMethodCreditCard,
  PaymentMethodC4Wallet,
  PaymentMethodApplePay,
  PaymentMethodJkoPay,
  PaymentMethodPoint,
} from 'utils/constants/paymentMethod.js';
import { tapPayRedirect } from 'utils/tappay';
import {
  checkNaturalNumber,
  checkTaxNumberV2,
  checkCarrier,
} from 'utils/_invoiceCheck.js';
import { API_RESPONSE } from 'apis/_const';
import ModalMemberValidation from 'components/UI/Modal/ModalMemberValidation';
import ModalWallet from 'components/UI/Modal/ModalWallet';
import ModalOutOfStock from 'components/UI/Modal/ModalOutOfStock';
import {
  PATH_MEMBER_EDIT,
  PATH_MAINTENANCE,
  PATH_PAYMENT_FAIL,
  PATH_ORDER,
  PATH_JKOPAY,
  PATH_RECEIVER_EDIT,
} from 'utils/constants/paths.js';
import { SHIPPING_MODEL } from 'utils/constants/shippingModel.js';
import { INVOICE_TYPE } from 'utils/constants/invoiceType.js';
import { TRADE_RESPONSE } from 'utils/constants/trade.js';
import ModalEmailCombine from 'components/Cart/ModalEmailCombine';

const Transaction = ({
  onFinished,
  returnContract,
  agreePolicy,
  qcPrime,
  setStartTransction,
  startTransction,
  getCartFinalStep,
  onFailed,
}) => {
  // const { onFinished, returnContract, agreePolicy } = props;
  const api = useAPI();
  const loading = useLoading();
  const payment = useSelector((state) => getters.getPayment(state));
  const cartList = useSelector((state) => getters.getCartList(state));
  const cartPrice = useSelector((state) => getters.getCartPrice(state));
  const userInfo = useSelector((state) => getters.getUserInfo(state));
  const creditInfo = useSelector((state) => getters.getCreditInfo(state));
  const cartAdditionItems = useSelector((state) =>
    getters.getCartAdditionItems(state)
  );
  const walletPassword = useRef('');
  const clpeValid = useRef(false);
  const walletValid = useRef(false);
  const orderData = useRef({});
  const saleOutList = useRef([]);
  const [modalEmail, setModalEmail] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const [showSaleOut, setShowSaleOut] = useState(false);
  const ENCODER_PAYMENT = {
    mobile: '6',
    pc: '5',
  };
  const dispatchData = useRef(null);
  const checkMobileCode = (code, callback) => {
    api
      .checkMobileCode({ barcode: code })
      .then((res) => {
        if (!res) {
          callback(false);
          return;
        }

        if (callback !== undefined) callback(true);
      })
      .catch((error) => {
        callback(false);
      });
  };

  const validateTaiwanPhoneNumber = (phoneNumber) => {
    // 使用正則表達式驗證台灣手機號碼格式
    // 台灣手機號碼格式為09開頭，後面接8個數字
    var regex = /^09\d{8}$/;

    return regex.test(phoneNumber);
  };

  const validPaymentCollectionData = (params, callback) => {
    let isValid = true;

    if (!params.receiverName) {
      alert('請新增收件人後再下單', () => {
        const addressId = _.get(dispatchData.current, 'addressId', '');
        if (addressId !== '') {
          navigate(PATH_RECEIVER_EDIT, {
            state: { addressId: addressId },
          });
        }
      });
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (cartList.length <= 0) {
      alert('購物車無商品。');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (!validateTaiwanPhoneNumber(params.receiverPhone)) {
      alert('收件人手機號碼格式錯誤', () => {
        const addressId = _.get(dispatchData.current, 'addressId', '');
        if (addressId !== '') {
          navigate(PATH_RECEIVER_EDIT, {
            state: { addressId: addressId },
          });
        }
      });
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    //錢包與紅利折抵不需要確認發票條款
    console.log('payment.payment', payment.payment);
    if (
      !returnContract &&
      payment.payment !== PaymentMethodPoint &&
      payment.payment !== PaymentMethodC4Wallet
    ) {
      alert('請勾選同意代為處理發票');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (!agreePolicy) {
      alert('請確認已閱讀瞭解條款內說明並打勾');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (
      params === undefined ||
      params === null ||
      Object.keys(params).length === 0
    ) {
      alert('缺少資料');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (params.shippingModel === SHIPPING_MODEL.Delivery) {
      if (params.receiverElevator === '') {
        alert('請選擇有無電梯');
        isValid = false;
        if (callback !== undefined) {
          callback(isValid);
        }
        return;
      }
      if (
        params.receiverReceive === undefined ||
        params.receiverReceive === '' ||
        params.receiverReceive === '0'
      ) {
        alert('請選擇簽收方式');
        isValid = false;
        if (callback !== undefined) {
          callback(isValid);
        }
        return;
      }
    }

    if (
      params.invoiceType === INVOICE_TYPE.Mobile &&
      (params.invoiceVehicle === '' ||
        params.invoiceVehicle === undefined ||
        params.invoiceVehicle === null)
    ) {
      alert('請輸入手機條碼');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    } else if (
      params.invoiceType === INVOICE_TYPE.Mobile &&
      params.invoiceVehicle !== '' &&
      !checkCarrier(params.invoiceVehicle)
    ) {
      alert('手機條碼不正確');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (
      params.invoiceType === INVOICE_TYPE.CitizenDigital &&
      (params.invoiceVehicle === '' ||
        params.invoiceVehicle === undefined ||
        params.invoiceVehicle === null)
    ) {
      alert('請輸入自然人憑證');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    } else if (
      params.invoiceType === INVOICE_TYPE.CitizenDigital &&
      params.invoiceVehicle !== '' &&
      !checkNaturalNumber(params.invoiceVehicle)
    ) {
      alert('自然人憑證不正確');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (
      params.invoiceType === INVOICE_TYPE.Tax &&
      (params.uniformNumbers === '' ||
        params.uniformNumbers === '00000000' ||
        params.uniformNumbers === undefined ||
        params.uniformNumbers === null)
    ) {
      alert('選擇公司用發票，請填寫統一編號。');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    } else if (
      params.invoiceType === INVOICE_TYPE.Tax &&
      params.uniformNumbers !== '' &&
      !checkTaxNumberV2(params.uniformNumbers)
    ) {
      alert('統編格式不正確');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (
      params.paymentType === undefined ||
      params.paymentType === PaymentMethodNone ||
      (params.paymentType === PaymentMethodCreditCard && !params.creditCardNo)
    ) {
      alert('請選擇支付方式');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (params.invoiceType === INVOICE_TYPE.Mobile) {
      checkMobileCode(params.invoiceVehicle, (result) => {
        if (!result) {
          alert('手機條碼格式錯誤');
          isValid = false;
          if (callback !== undefined) {
            callback(isValid);
          }
        }
        if (callback !== undefined) {
          callback(isValid);
        }
      });
    } else {
      if (callback !== undefined) {
        callback(isValid);
      }
    }
  };

  const checkStock = (storeId = '', callback = null) => {
    api
      .checkStock(storeId)
      .then((res) => {
        if (typeof res === 'object' && res.type === 'closeStore') {
          if (callback) callback(false);
          return;
        }
        if (!res) {
          if (callback) callback(false);
          return;
        }
        if (res.deleteItem.length > 0) {
          res.deleteItem.forEach((item) => {
            saleOutList.current.push(item.itemName);
          });
          setShowSaleOut(true);

          if (callback) callback(false);
          return;
        }
        if (callback) callback(true);
      })
      .catch((error) => {});
  };

  const gatherDataAndValidate = (callback) => {
    // const strPayment = sessionStorage.getItem('payment');
    // const payment = JSON.parse(strPayment);

    var cardNo = '';
    var expire = '';
    var cvc = '';
    var newCard = false;
    var isRemember = false;
    if (payment.payment === PaymentMethodCreditCard) {
      if (payment.cardInfo) {
        cardNo = payment.cardInfo.cardNo;
        expire = payment.cardInfo.expire;
        cvc = payment.cardInfo.cvc;
        newCard = payment.cardInfo.isNewCard;
        isRemember = payment.cardInfo.checked;
      } else {
        cardNo = creditInfo.creditCardNumber;
        expire = `${creditInfo.creditCardExpirationYear}${creditInfo.creditCardExpirationMonth}`;
      }
    }

    // const isInvoiceTypeDisabled = () => {
    //   const clpe = _.get(payment, 'clpe.value', 0);
    //   return (
    //     payment.payment === 20 ||
    //     (payment.payment === 24 && cartPrice.orderTotal - clpe === 0)
    //   );
    // };

    // const isInvoiceTypeDisabled =
    //   payment.payment === 20 ||
    //   (payment.payment === 24 && showOrderTotal === 0);

    if (cardNo !== undefined && cardNo !== null && cardNo !== '') {
      cardNo = cardNo.split(' ').join('');
    }

    const invoiceType = _.get(dispatchData.current, 'invoiceType', '');

    const clpe = _.get(payment, 'clpe.value', 0);
    const isInvoiceTypeDisabled =
      payment.payment === PaymentMethodC4Wallet ||
      (payment.payment === PaymentMethodPoint &&
        cartPrice.orderTotal - clpe === 0);

    let affiliateParams = {};
    const strAffiliateParams = localStorage.getItem('affiliateParams');
    if (strAffiliateParams) {
      const params = JSON.parse(strAffiliateParams);
      const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
      if (currentTimestampInSeconds < params.expireAt) {
        const {
          source,
          utm_source,
          affiliateCompany,
          ecid,
          p,
          expireAt,
          vtm_channel,
          sbid,
        } = params;

        affiliateParams = {
          source,
          utm_source,
          affiliateCompany,
          ecid,
          p,
          expireAt,
          ...(vtm_channel && { vtm_channel }),
          ...(sbid && { sbid }),
        };
      }
    }
    const productList = cartList.concat(cartAdditionItems);
    const parameters = {
      storeId: _.get(dispatchData.current, 'storeId', 0),
      ecorderPlatform: isMobile
        ? Number.parseInt(ENCODER_PAYMENT.mobile)
        : Number.parseInt(ENCODER_PAYMENT.pc),
      paymentType: payment.payment,
      wallet_pwd: walletPassword.current,
      shippingDate: _.get(dispatchData.current, 'shippingDate', ''),
      shippingPeriod: _.get(dispatchData.current, 'shippingPeriod', ''),
      shippingTimeslot: _.get(dispatchData.current, 'shippingTimeslot', ''),
      fastDeliveryPeriod: _.get(dispatchData.current, 'fastDeliveryPeriod', ''),
      shippingModel: _.get(dispatchData.current, 'shippingModel', ''),
      receiverName: _.get(dispatchData.current, 'receiverName', ''),
      receiverPhone: _.get(dispatchData.current, 'receiverPhone', ''),
      receiverAddress:
        _.get(dispatchData.current, 'receiverAddress', '') +
        _.get(dispatchData.current, 'floor', ''),
      receiverZip: _.get(dispatchData.current, 'receiverZip', ''),
      receiverElevator: _.get(dispatchData.current, 'receiverElevator', ''),
      receiverReceive: _.get(dispatchData.current, 'receiverReceive', ''),
      receiverAddressRemark: _.get(
        dispatchData.current,
        'receiverAddressRemark',
        ''
      ),
      receiverAddressId: _.get(dispatchData.current, 'addressId', ''),
      remark: _.get(dispatchData.current, 'remark', ''),
      invoiceType: isInvoiceTypeDisabled ? null : invoiceType,
      invoiceVehicle: isInvoiceTypeDisabled
        ? null
        : invoiceType === INVOICE_TYPE.Mobile ||
          invoiceType === INVOICE_TYPE.CitizenDigital
        ? _.get(dispatchData.current, 'invoiceVehicle', '')
        : '',
      uniformNumbers:
        invoiceType === INVOICE_TYPE.Tax
          ? _.get(dispatchData.current, 'uniformNumbers', '')
          : '',
      outofstockAction: '1',
      ecorderpayment: isMobile ? ENCODER_PAYMENT.mobile : ENCODER_PAYMENT.pc,
      creditCardNo: cardNo,
      expireYYMM: expire,
      cvsCode: cvc,
      isRemembered: newCard && isRemember ? true : !newCard ? true : false,
      products: productList.map((prod, index) => ({
        productid: prod.productId,
        itemName: prod.itemName,
        qty: prod.qty,
        itemOutofstockAction: prod.itemOutofstockAction,
      })),
      clpe_deduct:
        payment && payment.clpe && payment.clpe.point ? payment.clpe.point : 0,
      clpe_deduct_amount:
        payment && payment.clpe && payment.clpe.value ? payment.clpe.value : 0,
      new_card: newCard,
      affiliateParams: affiliateParams,
    };

    const storeId = _.get(dispatchData.current, 'storeId', 0);

    //檢查庫存
    validPaymentCollectionData(parameters, (valid) => {
      if (!valid) {
        if (onFailed) {
          onFailed();
        }
        loading.dismiss();
        return;
      }
      checkStock(storeId, (isValid) => {
        if (!isValid) {
          if (onFailed) {
            onFailed();
          }
          loading.dismiss();
          return;
        }
        callback(parameters);
      });
    });
  };

  // payment: 1（信用卡）、20（錢包）
  const checkPaymentMethod = () => {
    const isPoint = payment?.clpe?.point;
    const isWallet = payment.payment === PaymentMethodC4Wallet;
    // 檢查折抵全額
    if (
      payment?.clpe?.value &&
      payment.payment === PaymentMethodPoint &&
      !(
        payment.clpe.value ===
          cartPrice?.productTotal -
            (cartPrice?.discount - cartPrice?.shippingTotal > 0
              ? cartPrice?.discount - cartPrice?.shippingTotal
              : 0) &&
        cartPrice?.productTotal -
          (cartPrice?.discount - cartPrice?.shippingTotal > 0
            ? cartPrice?.discount - cartPrice?.shippingTotal
            : 0) ===
          cartPrice?.orderTotal
      )
    ) {
      alert('紅利金額錯誤，請檢查紅利金額。');
      if (onFailed) {
        onFailed();
      }
      return;
    }

    //如果是錢包或點數折抵 開啟手機驗證
    if ((isPoint || isWallet) && !clpeValid.current && !walletValid.current) {
      loading.dismiss();
      setShowValidation(true);
      return;
    }

    //如果是錢包 開啟錢包驗證
    if (payment.payment === PaymentMethodC4Wallet && walletValid.current) {
      const skey = _.get(userInfo, 'skey', '');
      if (skey === null || skey === undefined || skey === '') {
        const ocaToken = Cookies.get('OcaToken');
        api
          .getSkey({ accessToken: ocaToken })
          .then((res) => {
            const codeInt = Number.parseInt(res.code);
            if (codeInt === TRADE_RESPONSE.Success) {
              store.dispatch(
                actions.setUserInfo({
                  ...userInfo,
                  skey: res.data.skey,
                })
              );
              // 繼續跑錢包
              setTimeout(() => {
                setShowWallet(true);
              }, 200);
              return;
            } else if (codeInt === TRADE_RESPONSE.ErrorCouponRemove) {
              if (onFailed) {
                onFailed();
              }
            } else {
              if (onFailed) {
                onFailed();
              }
              alert(res.msg);
              return;
            }
          })
          .catch((error) => {
            if (onFailed) {
              onFailed();
            }
            alert(error);
          });
        return;
      } else {
        setShowWallet(true);
      }

      return;
    }
    const parameters = orderData.current;
    if (parameters.paymentType === PaymentMethodApplePay) {
      if (!qcPrime) {
        alert('ApplePay發生錯誤');
        if (onFailed) {
          onFailed();
        }
        return;
      }
      const param = {
        ...parameters,
        QCPrime: qcPrime,
      };
      makeOrder(param);
    } else {
      makeOrder(parameters);
    }
  };

  //呼叫訂單API
  const makeOrder = (parameters) => {
    // //Apple Pay
    api
      .makeOrder(parameters)
      .then((res) => {
        makeOrderAfter(res, parameters);
      })
      .catch((err) => {});
  };

  const pageCheck = (fn) => {
    api.getAppInfo().then((info) => {
      const nowDate = Date.parse(new Date());
      const constructionStart = Date.parse(info.underConstructionPeriodStart);
      const constructionEnd = Date.parse(info.underConstructionPeriodEnd);
      const isOnPeriod =
        nowDate > constructionStart && nowDate < constructionEnd;
      if (info.underConstruction && isOnPeriod) {
        sessionStorage.setItem(
          'MaintenanceMsg',
          info.underConstructionDescription
        );
        setTimeout(() => {
          navigate(PATH_MAINTENANCE, { replace: false });
        }, 400);
      } else {
        // 刪除暫存卡號
        store.dispatch(actions.setNewCards([]));
        store.dispatch(actions.setCartList([]));
        store.dispatch(actions.setQtyStatus(false));
        store.dispatch(actions.setCartPrice({}));
        fn();
      }
    });
  };

  const removeCoupons = () => {
    api
      .removeCoupons()
      .then((res) => {
        if (!res) {
          return;
        }
        if (typeof window !== 'undefined') {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const makeOrderAfter = (res, parameters) => {
    const codeInt = Number.parseInt(res.code);
    if (codeInt === TRADE_RESPONSE.Success) {
      setTimeout(() => {
        if (res.data.payment_url === '') {
          pageCheck(() => {
            alert('訂單已送出');
            //TODO::轉跳訂單頁
            navigate(
              `${PATH_ORDER}/${res.data.sfcc_transaction_no}?from=result`,
              {
                replace: true,
              }
            );
          });
        } else {
          //TODO::轉跳第三方支付頁
          console.log('makeOrderAfter', '轉跳第三方支付頁');
          pageCheck(() => {
            if (parameters.paymentType === PaymentMethodJkoPay) {
              navigate(PATH_JKOPAY, {
                replace: true,
                state: { url: res.data.payment_url },
              });
              // tapPayRedirect(res.data.payment_url);
            } else {
              navigate(res.data.payment_url, { replace: true });
            }
          });
        }
      }, 400);
      const invoice = {
        invoiceType: parameters.invoiceType,
        invoiceVehicle: parameters.invoiceVehicle,
        uniformNumbers: parameters.uniformNumbers,
      };
      localStorage.setItem('KeepInvoice', JSON.stringify(invoice));
      if (parameters.paymentType !== PaymentMethodPoint) {
        localStorage.setItem(
          'KeepPaymentType',
          JSON.stringify({ payment: parameters.paymentType })
        );
      } else {
        localStorage.removeItem('KeepPaymentType');
      }
      if (onFinished) onFinished();
    } else {
      loading.dismiss();

      switch (codeInt) {
        case TRADE_RESPONSE.ErrorCartMismatch: // 402: 與購物車資訊不符
          alert(res.reason, () => {
            if (typeof window !== 'undefined') {
              window.location.reload();
            }
          });
          break;
        case TRADE_RESPONSE.ErrorCouponRemove: // 405: 優惠券已被移除
          alert(res.reason, () => {
            if (onFailed) {
              onFailed();
            }
            removeCoupons();
          });
          break;
        case TRADE_RESPONSE.ErrorStoreRest:
          // 店休
          alert(res.reason);
          if (onFailed) {
            onFailed();
          }
          break;
        case codeInt >= TRADE_RESPONSE.ErrorRangeMin &&
          codeInt < TRADE_RESPONSE.ErrorRangeMax:
          pageCheck(() => {
            // todo::轉跳付款失敗頁
            navigate(
              `${PATH_PAYMENT_FAIL}/${res.data.sfcc_transaction_no}?reason=${res.reason}`,
              { replace: false }
            );
          });
          // 清除支付
          setTimeout(() => {
            // 刪除暫存卡號
            store.dispatch(actions.setNewCards([]));
          }, 1000);
          break;
        case TRADE_RESPONSE.ErrorTimeSlot:
          alert(res.reason);
          getTimeSlots();
          if (onFailed) {
            onFailed();
          }
          break;
        case TRADE_RESPONSE.ErrorEmailEmpty:
          setModalEmail(true);
          // alert(res.reason, () => {
          //   setModalEmail(true);
          // });
          break;
        default:
          alert(res.reason);
          if (onFailed) {
            onFailed();
          }
          break;
      }
      // if (codeInt === 405) {
      //   loading.dismiss();
      //   alert(res.reason, () => {
      //     removeCoupons();
      //   });
      //   return;
      // }

      // if (codeInt === 503) {
      //   loading.dismiss();
      //   //店休
      //   alert(res.reason, () => {});
      //   return;
      // }
      // if (codeInt >= 700 && codeInt < 800) {
      //   pageCheck(() => {
      //     loading.dismiss();
      //     //todo::轉跳付款失敗頁
      //     navigate(
      //       `/payment/fail/${res.data.sfcc_transaction_no}?reason=${res.reason}`,
      //       { replace: false }
      //     );
      //   });
      //   // 清除支付
      //   setTimeout(() => {
      //     // 刪除暫存卡號
      //     store.dispatch(actions.setNewCards([]));
      //   }, 1000);
      // } else if (codeInt === 600) {
      //   loading.dismiss();
      //   alert(res.reason);
      //   getTimeSlots();
      // } else {
      //   loading.dismiss();
      //   alert(res.reason);
      // }
    }

    //code==700 付款失敗 導到payment-fail  非700 ==400 badrequest alert(reason)
    //
    //code==200 && payment_url=="" 前往order/detail/[sfcc_transaction_no] 顯示訂單完成頁
    //code==200 && payment_url != "" 前往payment_url 3d驗證 交易完成後將轉導到payment/result?status="200 or 非200 "&trans="[sfcc_transaction_no]"
    //payment/result 這邊會根據status判斷 200視為成功前往order/detail/[sfcc_transaction_no] 顯示訂單完成頁  非200視為失敗前往message/payment-fail
  };

  useEffect(() => {
    if (startTransction) {
      setStartTransction(false);
      const strDispatch = localStorage.getItem('DispatchData');
      dispatchData.current = JSON.parse(strDispatch);

      loading.show();
      clpeValid.current = false;
      walletValid.current = false;
      gatherDataAndValidate((parameters) => {
        orderData.current = parameters;
        if (!parameters) {
          if (onFailed) {
            onFailed();
          }
          loading.dismiss();
          return;
        }

        checkPaymentMethod();
        // setTimeout(() => {
        //   checkPaymentMethod();
        // }, 2000);
      });
    }
  }, [startTransction]);

  const checkProducts = () => {
    saleOutList.current = [];
    setShowSaleOut(false);

    if (getCartFinalStep) getCartFinalStep();
  };

  return (
    <div>
      {showValidation && (
        <ModalMemberValidation
          open={showValidation}
          checkPaymentMethod={() => {
            setShowValidation(false);
            checkPaymentMethod();
          }}
          onClose={() => {
            if (onFailed) {
              onFailed();
            }
            setShowValidation(false);
          }}
          onCancel={() => {
            if (onFailed) {
              onFailed();
            }
            setShowValidation(false);
          }}
          clpeValid={clpeValid}
          walletValid={walletValid}
        />
      )}
      {showWallet && (
        <ModalWallet
          open={showWallet}
          onClose={() => {
            if (onFailed) {
              onFailed();
            }
            setShowWallet(false);
          }}
          onCancel={() => {
            if (onFailed) {
              onFailed();
            }
            setShowWallet(false);
          }}
          onFinished={() => {
            setShowWallet(false);
            const parameters = orderData.current;
            const param = {
              ...parameters,
              wallet_pwd: walletPassword.current,
            };
            loading.show();
            makeOrder(param);
          }}
          onOK={() => {}}
          walletPassword={walletPassword}
        />
      )}
      {showSaleOut && (
        <ModalOutOfStock
          open={showSaleOut}
          saleOutList={saleOutList.current}
          onClose={() => {
            checkProducts();
          }}
          onOk={() => {
            checkProducts();
          }}
        />
      )}
      {modalEmail && (
        <ModalEmailCombine
          open={modalEmail}
          onCancel={() => {
            if (onFailed) {
              onFailed();
            }
            setModalEmail(false);
          }}
          onClose={() => {
            if (onFailed) {
              onFailed();
            }
            setModalEmail(false);
          }}
          onUpdateFinished={() => {
            setModalEmail(false);
          }}
        />
      )}
    </div>
  );
};

export default Transaction;
