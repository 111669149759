import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

import { md } from 'utils/breakpoints';

const Container = styled.div`
  width: 375px;
  background-color: #fff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 16px 20px;

  @media (max-width: ${md}) {
    width: 90vw;
    padding: 16px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
  text-align: center;
  margin-bottom: 8px;
`;

const Input = styled.input`
  appearance: none;
  outline: none;
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  border: 1px solid #5fd2da;
  border-radius: 3px;
  padding: 12px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
`;

const Btn = styled.div`
  width: 100%;
  height: 52px;
  background-color: #5fd2da;
  border-radius: 30px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 52px;
  text-align: center;
  cursor: pointer;
  margin-top: 17px;
`;

const FailedItemBlock = styled.div`
  max-height: calc(100vh - 50vh);
  margin-top: 15px;
  overflow: auto;
`;
const FailedText = styled.div`
  font-size: 16px;
  line-height: 25px;
`;

const PopupInput = (props) => {
  const { onChange, onOk, placeholder, title, value, isFailed, couponMessage } =
    props;

  return (
    <Container>
      <Title>{title}</Title>
      <Input placeholder={placeholder} value={value} onChange={onChange} />

      <FailedItemBlock>
        {isFailed && <FailedText>{parse(couponMessage)}</FailedText>}
      </FailedItemBlock>

      <Btn onClick={onOk}>新增</Btn>
    </Container>
  );
};

export default PopupInput;
