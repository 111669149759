import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { navigate } from 'gatsby-link';

import { Checkbox } from 'components/UI/Checkbox';

import { useAPI } from 'utils/api';
import { getDispatchData } from 'utils/_dispatch.js';
import { setAddToCart, setRemoveFromCart } from 'utils/_gtag.js';
import { md } from 'utils/breakpoints';
import { PATH_HOME, PATH_PRODUCT } from 'utils/constants/paths.js';

import IconCounterAdd from 'images/counter-add.svg';
import IconCounterSub from 'images/counter-sub.svg';
import IconProductDelete from 'images/product-delete.svg';
import Cookies from 'js-cookie';

const ProductCheckbox = styled(Checkbox)`
  width: fit-content;
`;

const ProductCheckboxEmpty = styled.div``;

const ProductCard = styled.div`
  display: flex;
  align-items: center;
  margin: 0 24px;
  min-height: 135px;
  cursor: pointer;

  &:not(:first-child) {
    border-top: 1px solid #e3e3e3;
  }

  &.plastic-bag {
    border-top: 1px solid #5fd2da;

    ${ProductCheckbox} {
      margin-right: 16px;
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
`;

const ProductImgWrapper = styled.div`
  flex: 0 0 130px;
  display: flex;
  justify-content: center;
`;

const ProductImg = styled.div`
  width: 64px;
  height: 64px;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
`;

const ProductTitleWrapper = styled.div`
  flex: 0 0 250px;
  padding: 0 15px;

  &.plastic-bag {
    width: auto;
  }
`;

const ProductMainTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #333333;
`;

const ProductSubTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #999999;
`;

const Counter = styled.div`
  display: flex;
  visibility: hidden;
  padding: 15px;
`;

const CounterBtn = styled.div`
  width: 24px;
  height: 24px;
  background-color: #5fd2da;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  cursor: pointer;

  &.sub {
    background-image: url(${(props) => props.icon});
  }

  &.add {
    background-image: url(${(props) => props.icon});
  }
`;

const CounterInput = styled.input`
  width: 43px;
  height: 27px;
  border: 1px solid #e3e3e3;
  margin: 0 8px;
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  outline: none;
`;

const ProductPrice = styled.div`
  flex: 0 0 115px;
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  padding-left: 15px;
`;

const DeleteBtn = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-left: auto;
`;

const ProductContentBlock = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  align-self: stretch;
`;

const PromotionLabelList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
`;

const PromotionLabel = styled.div`
  display: inline-block;
  background-color: #fff4bc;
  border-radius: 30px;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 400;
  color: #3b3516;
`;

const ProductOriginalPrice = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #868686;
  text-decoration: line-through;

  @media (max-width: ${md}) {
    font-size: 12px;
  }
`;

const CheckboxWrapper = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
`;

const DeleteBtnWrapper = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Product = ({
  item,
  type,
  allChecked,
  checkItem,
  qtyCheckFinished,
  reload,
  saleOutHandle,
}) => {
  const api = useAPI();
  const isSoldOut = useRef(false);
  const clickTimeout = useRef(null);
  const lastConfirmQty = useRef(0);

  const [checkbox, setCheckbox] = useState(false);
  const [qtyDisable, setQtyDisable] = useState(true);
  const [qty, setQty] = useState(0);
  const [price, setPrice] = useState(0);
  const [showItem, setShowItem] = useState(true);

  const deleteItem = () => {
    // 先把商品隱藏
    setShowItem(false);
    const dispatchData = getDispatchData();
    api
      .removeCart({
        productId: item.productId,
        currentStoreId: _.get(dispatchData, 'storeId', ''),
        additionalItem: type === 'addition' ? 1 : 0,
      })
      .then((res) => {
        // GTAG: 6. 購物車中移除商品
        setRemoveFromCart(item);

        if (res.productItems.length <= 0) {
          alert('目前購物車沒有商品。', () => {
            navigate(PATH_HOME, { replace: true });
          });
          return;
        }

        if (reload) reload(res, 'delete');
      })
      .catch((error) => {
        // 有問題再開回來
        setShowItem(true);
        console.log(error);
      });
  };

  const updateItem = (mode) => {
    var count = 0;
    count = mode === 'increase' ? qty + 1 : qty - 1;

    if (count > item.c_maxOrderQuantity) {
      alert('已達商品購買上限');
      return;
    }
    const dispatchData = getDispatchData();
    api
      .updateCart({
        productId: item.productId,
        quantity: count,
        currentStoreId: _.get(dispatchData, 'storeId', ''),
      })
      .then((res) => {
        console.log('updateCart', res);
        if (!res) return;

        if (reload) reload(res, 'update');
        setQty(count);
        res?.productItems.forEach((element) => {
          if (element.productId === item.productId && !element.isAdditionItem) {
            setPrice(element.price);
          }
        });

        // ALVIN 2023/05/23 update redux cart & cart price
        // store.dispatch(actions.setCartList(res.productItems));
        // store.dispatch(actions.setCartPrice(res));

        if (mode === 'increase') {
          // 從cookie中取得attributionToken
          const attributionToken = Cookies.get('attributionToken');
          // GTAG: 加商品購物車數量
          setAddToCart(res, item.productId, item, undefined, attributionToken);
        } else {
          setRemoveFromCart(item);
        }
      });

    // 先更改畫面商品數量
    /* setQty(count); */

    /* // 清除之前的計時器
    clearTimeout(clickTimeout.current);

    // 延遲1秒後執行
    clickTimeout.current = setTimeout(() => {
      // 更新數量
      updateCartAPI(count, mode);
    }, 1000); // 1秒延遲 */
  };

  /* const updateCartAPI = (count, mode) => {
    const dispatchData = getDispatchData();
    api
      .updateCart({
        productId: item.productId,
        quantity: count,
        currentStoreId: _.get(dispatchData, 'storeId', ''),
      })
      .then((res) => {
        console.log('updateCart', res);
        if (!res) {
          setQty(lastConfirmQty.current);
          return;
        }

        if (reload) reload(res, 'update');
        setQty(count);
        lastConfirmQty.current = count;

        res?.productItems.forEach((element) => {
          if (element.productId === item.productId) {
            setPrice(element.price);
          }
        });

        // ALVIN 2023/05/23 update redux cart & cart price
        // store.dispatch(actions.setCartList(res.productItems));
        // store.dispatch(actions.setCartPrice(res));

        if (mode === 'increase') {
          // GTAG: 加商品購物車數量
          setAddToCart(res, item.productId, item);
        } else {
          setRemoveFromCart(item);
        }
      });
  }; */

  const increaseQty = () => {
    updateItem('increase');
  };

  const decreaseQty = () => {
    const countQty = qty - 1;
    if (countQty === 0) {
      deleteItem();
      return;
    } else if (countQty > 0) {
      updateItem('decrease');
    } else {
      return;
    }
  };

  const getCartCheckQty = () => {
    const parameters = {
      productlist: [
        {
          productId: item.productId,
          qty: item.qty,
        },
      ],
    };
    api
      .getCartCheckQty(parameters)
      .then((res) => {
        console.log('getCartCheckQty', res);
        if (!res) return;
        let data = {};
        for (let i = 0; i < res.productItems.length; i++) {
          const element = res.productItems[i];
          if (element.productId === item.productId) {
            // 同商品如果檢查到第二次處理(加價購商品與一般商品都在購物車時)
            // 同商品在檢查時 加價購商品的remain會返回false
            // remain不是代表缺貨 是不可購買 缺貨是結帳前checkStock那隻api在檢查
            // 以一般商品的remain為主要判斷可不可購買
            if (data.hasOwnProperty(element.productId)) {
              //第二次如果判斷是一般商品 改寫isSoldOut
              if (!element.isbonus) {
                isSoldOut.current = element.remain === true ? false : true;
                break;
              }
            }
            // 把第一次檢查的商品先keep起來
            const tmp = {
              [element.productId]: element.remain,
            };
            Object.assign(data, tmp);
            isSoldOut.current = element.remain === true ? false : true;
          }
        }
        setQtyDisable(isSoldOut.current);
        if (isSoldOut.current) {
          saleOutHandle(item.itemName);
        }
        if (qtyCheckFinished) qtyCheckFinished('pc');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickProduct = () => {
    navigate(`${PATH_PRODUCT}?pid=${item.productId}`);
  };

  useEffect(() => {
    /* lastConfirmQty.current = item.qty; */
    setQty(item.qty);
    setPrice(item.price);
    getCartCheckQty();
  }, []);

  useEffect(() => {
    setCheckbox(allChecked);
  }, [allChecked]);

  useEffect(() => {
    checkItem(item, !checkbox);
  }, [checkbox]);

  console.log('item', item);

  return showItem ? (
    <ProductCard disabled={qtyDisable} onClick={handleClickProduct}>
      <CheckboxWrapper onClick={(e) => e.stopPropagation()}>
        {type !== 'addition' ? (
          <ProductCheckbox
            value={checkbox}
            onChange={(e) => setCheckbox(e.target.checked)}
          />
        ) : (
          <ProductCheckboxEmpty />
        )}
      </CheckboxWrapper>

      <ProductImgWrapper>
        <ProductImg imageUrl={item.image} />
      </ProductImgWrapper>

      <ProductTitleWrapper>
        <ProductMainTitle>{item.itemName}</ProductMainTitle>

        <ProductSubTitle>{item.itemSpec}</ProductSubTitle>

        {item.productPromotions?.length > 0 && type !== 'addition' && (
          <PromotionLabelList>
            {item.productPromotions?.map((promotion) => {
              if (promotion.calloutMsg === '') return;
              return <PromotionLabel>{promotion.calloutMsg}</PromotionLabel>;
            })}
          </PromotionLabelList>
        )}
      </ProductTitleWrapper>

      <ProductContentBlock>
        <Counter
          style={{
            visibility: `${type !== 'addition' ? 'visible' : 'hidden'}`,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <CounterBtn
            className="sub"
            icon={IconCounterSub}
            onClick={() => decreaseQty()}
          />

          <CounterInput value={qty}></CounterInput>

          <CounterBtn
            className="add"
            icon={IconCounterAdd}
            onClick={() => increaseQty()}
          />
        </Counter>

        <ProductPrice>
          <div>{`$${price}`}</div>
          {item.productPromotions?.length > 0 &&
            item.nonAdjustedPrice > item.price &&
            type !== 'addition' && (
              <ProductOriginalPrice>
                ${item.price * item.qty}
              </ProductOriginalPrice>
            )}
        </ProductPrice>

        <DeleteBtnWrapper onClick={(e) => e.stopPropagation()}>
          <DeleteBtn src={IconProductDelete} onClick={() => deleteItem()} />
        </DeleteBtnWrapper>
      </ProductContentBlock>
    </ProductCard>
  ) : (
    <div></div>
  );
};

export default Product;
