import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import Modal from 'components/UI/Modal';
import { Checkbox } from 'components/UI/Checkbox';
import Payment from 'payment';
import { useCallbacks } from 'utils/callback';

const Container = styled.div`
  width: 375px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
  margin-bottom: 11px;
  text-align: center;
`;

const ContentWrapper = styled.div``;

const ContentRow = styled.div`
  &:nth-child(1) {
    margin-bottom: 8px;
  }

  &:nth-child(2) {
    margin-bottom: 16px;
    display: flex;
    gap: 0 8px;
  }

  &:nth-child(3) {
    margin-bottom: 16px;

    .checkbox-label {
      font-size: 12px;
      font-weight: 400;
      color: #333333;
    }
  }

  &:nth-child(4) {
    margin-bottom: 24px;
  }
`;

const InputLabel = styled.div`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #3b3516;
`;

const Input = styled.input`
  width: 100%;
  height: 52px;
  appearance: none;
  outline: none;
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  border-radius: 3px;
  padding: 14px 12px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
`;

const InputBlock = styled.div``;

const NoticeText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  padding: 8px 16px;
  background-color: #f2f2f2;
`;

const CreditText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #ff0000;
  padding: 8px 16px;
`;

const ButtonGroup = styled.div`
  padding-top: 10px;
  display: flex;
  gap: 0 10px;
`;

const Btn = styled.div`
  flex: 1;
  height: 52px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  line-height: 52px;
  cursor: pointer;
`;

const BtnCancel = styled(Btn)`
  background-color: #c4c4c4;
`;

const BtnConfirm = styled(Btn)`
  background-color: #5fd2da;
`;

const ModalCreditCard = (props) => {
  const callback = useCallbacks();
  const newCardInfo = useSelector((state) => getters.getNewCards(state));
  const { open, onClose, setModalCreditCard } = props;
  const [checkbox, setCheckbox] = useState(false);
  const [creditCard, setCreditCard] = useState('');
  const [expire, setExpire] = useState('');
  const [cvc, setCvc] = useState('');

  const onChangeCreditCard = (event) => {
    setCreditCard(event.target.value);
    Payment.formatCardNumber(event.target);
  };
  const onChangeExpire = (event) => {
    setExpire(event.target.value);
    Payment.formatCardExpiry(event.target);
  };

  const onChangeCreditCVC = (event) => {
    setCvc(event.target.value);
    Payment.formatCardCVC(event.target);
  };

  const validCardInfo = (info) => {
    const cardValid = Payment.fns.validateCardNumber(info.cardNo);
    if (!cardValid) {
      alert('卡號輸入錯誤');
      return false;
    }
    const expireValid = Payment.fns.validateCardExpiry(info.expire);
    if (!expireValid) {
      alert('有效日期輸入錯誤');
      return false;
    }
    const cvcValid = Payment.fns.validateCardCVC(info.cvc);
    if (!cvcValid) {
      alert('安全碼輸入錯誤');
      return false;
    }
    return true;
  };

  const onCardSubmit = (event) => {
    event.preventDefault();
    const newCardData = {
      cardNo: creditCard,
      expire: expire,
      cvc: cvc,
      checked: checkbox,
    };
    if (validCardInfo(newCardData)) {
      var selectIndexValue = 1000;
      if (
        newCardInfo !== undefined &&
        newCardInfo !== null &&
        newCardInfo.length > 0
      ) {
        selectIndexValue = newCardInfo.length + 1000;
      }
      store.dispatch(actions.setNewCards([...newCardInfo, newCardData]));
      const paymentEditCard = callback.getCallback('paymentEditCard');
      paymentEditCard('add', selectIndexValue);
      // setSelectPayment(selectIndexValue);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <Title>付款方式</Title>
        <ContentWrapper>
          <ContentRow>
            <InputLabel>卡號</InputLabel>
            <Input
              text={creditCard}
              maxLength="19"
              type="tel"
              onChange={onChangeCreditCard}
            />
          </ContentRow>
          <ContentRow>
            <InputBlock>
              <InputLabel>有效日期</InputLabel>
              <Input
                text={expire}
                placeholder="MM/YY"
                maxLength="7"
                type="tel"
                onChange={onChangeExpire}
              />
            </InputBlock>
            <InputBlock>
              <InputLabel>安全碼</InputLabel>
              <Input
                text={cvc}
                label="安全碼"
                maxLength="4"
                type="tel"
                onChange={onChangeCreditCVC}
              />
            </InputBlock>
          </ContentRow>
          <ContentRow>
            <CreditText>
              【因系統網頁功能調整，信用卡卡號請自行填入後進行結帳作業，如需記憶卡號請勾選設定快速結帳功能。】
            </CreditText>
          </ContentRow>
          <ContentRow>
            <Checkbox
              value={checkbox}
              onChange={(e) => setCheckbox(e.target.checked)}
            >
              我同意本次付款資訊,並設定為快速結帳。(可節省您下次
              結帳時間,且保有修改、刪除之權利。)
            </Checkbox>
          </ContentRow>
          <ContentRow>
            <NoticeText>
              本公司採用喬睿科技TapPay SSL交易系統,通過PCI-
              DSS國際信用卡組織Visa、MastreCard等產業資料安全 Level
              1等級,周全保護您的信用卡資料安全。
            </NoticeText>
          </ContentRow>
          <ButtonGroup>
            <BtnCancel
              onClick={() => {
                const paymentEditCard = callback.getCallback('paymentEditCard');
                paymentEditCard('cancel', null);
              }}
            >
              取消
            </BtnCancel>
            <BtnConfirm onClick={onCardSubmit}>確認</BtnConfirm>
          </ButtonGroup>
        </ContentWrapper>
      </Container>
    </Modal>
  );
};

export default ModalCreditCard;
