import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import store, { actions, getters } from 'redux/store';

import Modal from 'components/UI/Modal';

import { useAPI } from 'utils/api';

import IconValidation from 'images/validation.svg';

const Btn = styled.div`
  flex: 0 0 129px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 30px;
  cursor: pointer;
`;

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px 0;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  text-align: center;
`;

const NoticeBlock = styled.div`
  background-color: #f8f8f8;
  padding: 8px 12px;
  display: flex;
`;

const StyledIconValidation = styled.img`
  margin-right: 12px;
`;

const NoticeText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  flex-basis: 128px;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 500;
  color: #868686;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 10px;
  width: 100%;
`;

const CancelBtn = styled(Btn)`
  background-color: #c4c4c4;
`;

const OkBtn = styled(Btn)`
  background-color: #5fd2da;
`;

const ModalMemberValidation = ({
  onCancel,
  onClose,
  onOk,
  open,
  clpeValid,
  walletValid,
  checkPaymentMethod,
}) => {
  const api = useAPI();

  const userInfo = useSelector((state) => getters.getUserInfo(state));
  const payment = useSelector((state) => getters.getPayment(state));

  const [code, setCode] = useState('');

  const onChangeCode = (e) => {
    const value = e.target.value;
    if (!(/^\d+$/.test(value) || value === '')) return;
    setCode(value);
  };

  const validCode = (code, callback) => {
    // if (code === '3345678') {
    //   if (callback) callback(true);
    // } else {
    api
      .validCode({ mobile: userInfo.mobile, code })
      .then((res) => {
        setCode('');
        if (!res) {
          if (callback) callback(false);
          return;
        }
        if (callback) callback(true);
      })
      .catch((error) => {
        setCode('');
      });
    // }
  };

  const onNeedValidCode = () => {
    validCode(code, (finished) => {
      if (!finished) return;
      if (payment.payment === 20) {
        walletValid.current = true;
        checkPaymentMethod();
        return;
      }
      // use point pass
      clpeValid.current = true;
      checkPaymentMethod();
    });
  };

  useEffect(() => {
    if (!open) return;
    api
      .sendValidCode({ mobile: userInfo.mobile })
      .then((res) => {
        if (!res) return;
      })
      .catch((error) => {});
  }, [open]);

  return (
    <Modal open={open} width={332} onClose={onClose}>
      <Container>
        <Title>會員驗證</Title>
        <NoticeBlock>
          <StyledIconValidation src={IconValidation} />
          <NoticeText>請輸入簡訊傳送的驗證碼，謝謝。</NoticeText>
        </NoticeBlock>
        <Input
          placeholder="請輸入驗證碼"
          inputMode="numeric"
          maxLength={6}
          value={code}
          onChange={onChangeCode}
        />
        <ButtonGroup>
          <CancelBtn onClick={onCancel}>取消</CancelBtn>
          <OkBtn onClick={() => onNeedValidCode()}>確認付款</OkBtn>
        </ButtonGroup>
      </Container>
    </Modal>
  );
};

export default ModalMemberValidation;
