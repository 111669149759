//手機條碼
const checkCarrier = (str = '') => {
  // 檢查字串長度是否為 8
  if (str.length !== 8) {
    return false;
  }

  // 檢查第一碼是否為 '/'
  if (str.charAt(0) !== '/') {
    return false;
  }

  // 檢查剩餘七碼是否由數字、大寫英文和特殊符號組成
  const regex = /^[0-9A-Z.\-+]+$/;
  if (!regex.test(str.substring(1))) {
    return false;
  }

  return true;
};

//自然人憑證檢查
const checkNaturalNumber = (naturalNumber = '') => {
  if (naturalNumber === '') {
    return true;
  }

  var regexp = /^[a-zA-Z]{2}[0-9]{14}$/;

  return regexp.test(naturalNumber);
};

//統一編號檢查
const checkTaxNumber = (gui_number = '') => {
  if (gui_number === '') {
    return true;
  } else if (gui_number === '00000000') {
    return false;
  }
  const cx = [1, 2, 1, 2, 1, 2, 4, 1];
  const cnum = [...gui_number];
  let sum = 0;
  const cc = (num) => {
    let total = num;
    if (total > 9) {
      const s = total.toString();
      const n1 = s.slice(0, 1) * 1;
      const n2 = s.slice(1, 2) * 1;
      total = n1 + n2;
    }
    return total;
  };
  if (gui_number.length !== 8) {
    // alert('統編錯誤，要有 8 個數字');
    return;
  }
  for (const [index, item] of cnum.entries()) {
    if (gui_number.codePointAt() < 48 || gui_number.codePointAt() > 57) {
      // alert('統編錯誤，要有 8 個 0-9 數字組合');
      continue;
    }
    sum += cc(item * cx[index]);
  }
  if (sum % 10 === 0) {
    // alert('統編正確');
    return true;
  } else if (cnum[6] === '7' && (sum + 1) % 10 === 0) {
    // alert('統編正確2');
    return true;
  } else {
    // alert('統編錯誤');
    return false;
  }
};

const checkTaxNumberV2 = (gui_number = '') => {
  let rule = '12121241';
  if (gui_number.length !== 8) {
    return false;
  }
  let isSeven = false;
  // console.log('checkTaxNumberV2 !== 7', gui_number);
  if (gui_number[gui_number.length - 2] === '7') {
    // console.log('checkTaxNumberV2 === 7', gui_number);
    isSeven = true;
  }

  let z1 = [];
  let z2 = [];
  for (let i = 0; i < gui_number.length; i++) {
    let result = parseInt(gui_number[i]) * parseInt(rule[i]);
    if (result >= 10) {
      let a = Math.floor(result / 10);
      let b = result % 10;
      if (i === 6 && isSeven) {
        let c = a + b;
        let d = Math.floor(c / 10);
        let e = c % 10;

        z1.push(d);
        z2.push(e);
      } else {
        z1.push(a);
        z1.push(b);
        z2.push(a);
        z2.push(b);
      }
    } else {
      z1.push(result);
      z2.push(result);
    }
  }
  // console.log('checkTaxNumberV2 z1', z1);
  // console.log('checkTaxNumberV2 z2', z2);
  let r1 = 0;
  z1.forEach((element) => {
    r1 += element;
  });
  let r2 = 0;
  z2.forEach((element) => {
    r2 += element;
  });
  // console.log('checkTaxNumberV2 r1', r1);
  // console.log('checkTaxNumberV2 r2', r2);
  if (isSeven) {
    if (r1 % 5 === 0 || r2 % 5 === 0) {
      return true;
    }
  } else {
    if (r1 % 5 === 0) {
      return true;
    }
  }

  return false;
};
export { checkNaturalNumber, checkTaxNumber, checkCarrier, checkTaxNumberV2 };
