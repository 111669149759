import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Modal from 'components/UI/Modal';

const Btn = styled.div`
  flex: 1;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 30px;
  cursor: pointer;
`;

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 12px;
`;

const DeleteList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px 0;
  margin-bottom: 24px;
`;

const ProductName = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #868686;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 10px;
  width: 100%;
`;

const OkBtn = styled(Btn)`
  background-color: #5fd2da;
  flex: 0 0 268px;
`;

const ModalOutOfStock = ({ onClose, onOk, open, saleOutList }) => {
  return (
    <Modal open={open} width={332} onClose={onClose}>
      <Container>
        <Title>以下商品暫時無法購買，將為您將該商品從購物車當中刪除：</Title>
        <DeleteList>
          {saleOutList.map((item, index) => {
            return <ProductName>{item}</ProductName>;
          })}
        </DeleteList>
        <ButtonGroup>
          <OkBtn onClick={onOk}>確認</OkBtn>
        </ButtonGroup>
      </Container>
    </Modal>
  );
};

export default ModalOutOfStock;
