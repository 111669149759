export const TRADE_RESPONSE = {
  Success: 200,
  ErrorCartMismatch: 402,
  ErrorStoreRest: 503,
  ErrorCouponRemove: 405,
  ErrorEmailEmpty: 428,
  ErrorRangeMax: 800,
  ErrorRangeMin: 700,
  ErrorTimeSlot: 600,
};
