import React, { useState, useEffect } from 'react';
import store, { actions, getters } from 'redux/store';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import Skeleton from 'react-loading-skeleton';
import ModalDiscount from 'components/Cart/ModalDiscount';
import { md } from 'utils/breakpoints';
import {
  setBeginCheckout,
  setAddPaymentInfo,
  setAddShippingInfo,
} from 'utils/_gtag.js';
import {
  PaymentMethodCreditCard,
  PaymentMethodC4Wallet,
  PaymentMethodApplePay,
  PaymentMethodLinePay,
  PaymentMethodJkoPay,
  PaymentMethodPoint,
  PaymentMethodNone,
} from 'utils/constants/paymentMethod.js';

import { checkApplePay, applePayAvailable, applePay } from 'utils/tappay';

import IconDiscountNotice from 'images/discount-notice.svg';
import IconTooltipClose from 'images/close-2.svg';

const Container = styled.div`
  flex: 1;
  padding: 28px 14px;
  display: flex;
  flex-direction: column;
  gap: 13px 0;

  @media (max-width: ${md}) {
    padding: 15px;
  }
`;

const Tooltip = styled.div`
  width: 310px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: absolute;
  top: calc(100% + 15px);
  left: -50px;
  z-index: 100;
  padding: 12px 16px;

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    background-color: #fff;
    position: absolute;
    top: -8px;
    left: 53px;
    transform: rotate(45deg);
  }
`;

const TooltipCloseBtn = styled.div`
  background-image: url(${IconTooltipClose});
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 12px;
  right: 16px;
  cursor: pointer;
`;

const PriceSectionRow = styled.div`
  display: flex;
  font-weight: 400;
  color: #333333;

  &.free-shipping {
    display: none;

    @media (max-width: ${md}) {
      display: inherit;
    }
  }

  &.total {
    font-size: 16px;
    font-weight: 700;
  }
`;

const PriceSectionRowLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
`;

const PriceSectionRowValue = styled.div`
  margin-left: auto;
  font-size: 18px;

  &.discount {
    color: #5fd2da;
  }
`;

const BtnOrderWrapper = styled.div`
  padding-top: 16px;
  border-top: 1px solid #e4e4e4;

  @media (max-width: ${md}) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.11);
    padding: 19px 13px;
    z-index: 100;
  }
`;

const BtnOrder = styled.div`
  background-color: #5fd2da;
  width: 288px;
  height: 62px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 16px 28px;
  margin: 0 auto;
  cursor: pointer;

  @media (max-width: ${md}) {
    width: 100%;
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: #c4c4c4;
      pointer-events: none;
    `}
`;

const OrderItemNum = styled.div`
  background-color: #f9e05f;
  font-size: 20px;
  font-weight: 400;
  color: #3b3516;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  margin-left: 4px;
`;

const OrderTotalPrice = styled.div`
  margin-left: auto;
`;

const FreeShipping = styled.div`
  width: 100%;
  height: 36px;
  background-color: #fdf8e4;
  border: 1px solid #f2ecd2;
  font-size: 14px;
  font-weight: 400;
  color: #3b3516;
  align-items: center;
  justify-content: center;
  display: none;

  @media (max-width: ${md}) {
    display: flex;
  }

  > span {
    font-weight: 700;
  }
`;

const DiscountNoticeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  position: relative;

  > img {
    cursor: pointer;
  }
`;

const DiscountList = styled.div`
  width: 90%;
`;

const Discount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 0 10px;
  padding: 8px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }
`;

const DiscountDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #333333;
`;

const DiscountPrice = styled.div``;

const PriceSection = ({ className, fetchOrder }) => {
  const cartPrice = useSelector((state) => getters.getCartPrice(state));
  const qtyStatus = useSelector((state) => getters.getQtyStatus(state));
  const payment = useSelector((state) => getters.getPayment(state));
  const info = useSelector((state) => getters.getInfo(state));
  const shippingModel = useSelector((state) => getters.getShippingModel(state));
  const changeShippingModel = useSelector((state) =>
    getters.getChangeShippingModel(state)
  );

  const [freeShippingAmount, setFreeShippingAmount] = useState('0');
  const [showOrderTotal, setShowOrderTotal] = useState(0);
  const [carNum, setCarNum] = useState(0);
  const [showDiscountTooltip, setShowDiscountTooltip] = useState(false);
  const [showDiscountModal, setShowDiscountModal] = useState(false);

  const checkBonus = (total, tProductTotal, tDiscount, tShipping) => {
    var tmpPayment = payment;
    if (
      info.cart?.bonusThreshold !== undefined &&
      info.cart?.bonusThreshold !== null &&
      info.cart?.bonusThreshold > total
    ) {
      tmpPayment = {
        ...tmpPayment,
        payment: PaymentMethodNone,
        clpe: {
          value: 0,
          point: payment?.clpe?.point,
        },
      };
    }

    // 檢查點數是否正常, 自動調降紅利點數
    if (
      tmpPayment &&
      tmpPayment.clpe &&
      tmpPayment.clpe.value &&
      tmpPayment.clpe.value >
        tProductTotal - (tDiscount - tShipping > 0 ? tDiscount - tShipping : 0)
    ) {
      tmpPayment = {
        ...tmpPayment,
        clpe: {
          value:
            tProductTotal -
            (tDiscount - tShipping > 0 ? tDiscount - tShipping : 0),
          point: payment.clpe.point,
        },
      };
    }

    // 檢查是否全額折抵
    if (
      tmpPayment &&
      tmpPayment.clpe &&
      tmpPayment.clpe.value &&
      tmpPayment.clpe.value ===
        tProductTotal -
          (tDiscount - tShipping > 0 ? tDiscount - tShipping : 0) &&
      tProductTotal -
        (tDiscount - tShipping > 0 ? tDiscount - tShipping : 0) ===
        total
    ) {
      tmpPayment = {
        ...tmpPayment,
        payment: PaymentMethodPoint,
      };
    } else if (tmpPayment && tmpPayment.payment === 24) {
      // 如果目前是全額折抵，但外送服務費沒有扣抵
      tmpPayment = {
        ...tmpPayment,
        payment: PaymentMethodNone,
      };
    }

    calcShowOrderTotal(
      total,
      tmpPayment !== undefined && tmpPayment.clpe && tmpPayment.clpe.value
        ? tmpPayment.clpe.value
        : 0
    );
    store.dispatch(actions.setPaymentUpdate(true));
    store.dispatch(
      actions.setPayment({
        ...payment,
        ...tmpPayment,
      })
    );
  };

  const calcShowOrderTotal = (oTotal, clpeValue) => {
    return setShowOrderTotal(oTotal - clpeValue);
  };

  const gtagCheckoutProcess = () => {
    setBeginCheckout(cartPrice);
    setAddPaymentInfo(payment, cartPrice);
    setAddShippingInfo(shippingModel, cartPrice);
  };

  const handleClickDiscountIcon = () => {
    isMobile ? setShowDiscountModal(true) : setShowDiscountTooltip(true);
  };

  const confirmOrder = () => {
    gtagCheckoutProcess();
    if (payment.payment === 12) {
      // applePay(parameters);
      applePay()
        .then((res) => {
          fetchOrder(res.prime);
        })
        .catch((msg) => alert(msg));
    } else {
      fetchOrder();
    }
  };

  useEffect(() => {
    if (Object.keys(cartPrice).length === 0) {
      return;
    }

    setFreeShippingAmount(
      shippingModel === '1'
        ? _.get(cartPrice, 'shippingMethod.freeShippingAmount', '0')
        : _.get(cartPrice, 'shippingMethod.pickOnStoreAmount', '0')
    );

    let itemTotalCount = 0;

    cartPrice.productItems.forEach((element) => {
      // 改為計算總數量
      itemTotalCount = itemTotalCount + element.qty;
      // 扣掉垃圾袋不計算
      if (
        element.productId === cartPrice?.recycleBag?.productId ||
        element.productId === cartPrice?.recyclebag?.productId
      ) {
        itemTotalCount = itemTotalCount - element.qty;
      }
    });

    setCarNum(itemTotalCount);

    //當資料變動時計算紅利點數
    checkBonus(
      cartPrice.orderTotal,
      cartPrice.productTotal,
      cartPrice.discount,
      cartPrice.shippingTotal
    );
  }, [cartPrice]);

  useEffect(() => {
    const clpe = _.get(payment, 'clpe.value', 0);

    setShowOrderTotal(cartPrice.orderTotal - clpe);
    /* if (shippingModel === '1') {
      setShowOrderTotal(cartPrice.orderTotal - clpe);
    } else {
      setShowOrderTotal(cartPrice.productTotal - clpe);
    } */

    if (applePayAvailable() && payment.payment === PaymentMethodApplePay) {
      const clpe = _.get(payment, 'clpe.value', 0);
      checkApplePay(cartPrice.orderTotal - clpe)
        .then(() => {})
        .catch((msg) => alert(msg));
    }
  }, [payment]);

  useEffect(() => {
    if (applePayAvailable() && payment.payment === PaymentMethodApplePay) {
      const clpe = _.get(payment, 'clpe.value', 0);
      checkApplePay(cartPrice.orderTotal - clpe)
        .then(() => {})
        .catch((msg) => alert(msg));
    }
  }, []);

  return (
    <>
      <Container className={className}>
        {Object.keys(cartPrice).length > 0 ? (
          <>
            {freeShippingAmount !== '0' && freeShippingAmount !== 0 && (
              <PriceSectionRow className="free-shipping">
                <FreeShipping>
                  尚差 <span>${freeShippingAmount}</span> 以達
                  {shippingModel === '1' ? '免運' : '店取門檻'}
                </FreeShipping>
              </PriceSectionRow>
            )}
            <PriceSectionRow>
              <PriceSectionRowLabel>小計</PriceSectionRowLabel>
              {cartPrice.productTotal !== undefined && !changeShippingModel && (
                <PriceSectionRowValue>
                  {`$${_.get(cartPrice, 'productTotal', '0')}`}
                </PriceSectionRowValue>
              )}
            </PriceSectionRow>
            {/*如果是外送才需要顯示外送服務費*/}
            {shippingModel === '1' && (
              <PriceSectionRow>
                <PriceSectionRowLabel>外送服務費</PriceSectionRowLabel>
                {cartPrice.shippingTotal !== undefined &&
                  !changeShippingModel && (
                    <PriceSectionRowValue>{`$${_.get(
                      cartPrice,
                      'shippingTotal',
                      0
                    )}`}</PriceSectionRowValue>
                  )}
              </PriceSectionRow>
            )}
            {cartPrice.discount > 0 && !changeShippingModel && (
              <PriceSectionRow>
                <PriceSectionRowLabel>
                  折扣
                  <DiscountNoticeWrapper>
                    <img
                      src={IconDiscountNotice}
                      onClick={handleClickDiscountIcon}
                    />
                    {showDiscountTooltip && (
                      <Tooltip>
                        <TooltipCloseBtn
                          onClick={() => setShowDiscountTooltip(false)}
                        />
                        <DiscountList>
                          {cartPrice.discountList.map((discount) => {
                            return (
                              <Discount>
                                <DiscountDescription>
                                  {discount.title}
                                </DiscountDescription>
                                <DiscountPrice>-{discount.price}</DiscountPrice>
                              </Discount>
                            );
                          })}
                        </DiscountList>
                      </Tooltip>
                    )}
                  </DiscountNoticeWrapper>
                </PriceSectionRowLabel>
                <PriceSectionRowValue className="discount">
                  {`-$${cartPrice.discount}`}
                </PriceSectionRowValue>
              </PriceSectionRow>
            )}
            {payment?.clpe &&
              payment?.clpe.value !== 0 &&
              !changeShippingModel && (
                <PriceSectionRow>
                  <PriceSectionRowLabel>紅利折抵</PriceSectionRowLabel>
                  <PriceSectionRowValue className="discount">{`-$${payment?.clpe?.value}`}</PriceSectionRowValue>
                </PriceSectionRow>
              )}
            <PriceSectionRow className="total">
              <PriceSectionRowLabel>總計</PriceSectionRowLabel>
              {showOrderTotal >= 0 && !changeShippingModel && (
                <PriceSectionRowValue>{`$${showOrderTotal}`}</PriceSectionRowValue>
              )}
            </PriceSectionRow>
          </>
        ) : (
          <>
            <Skeleton count={1} inline />
            <Skeleton count={1} inline />
            <Skeleton count={1} inline />
          </>
        )}
        <BtnOrderWrapper>
          <BtnOrder disabled={!qtyStatus} onClick={confirmOrder}>
            確認下單
            <OrderItemNum>{`${carNum}`}</OrderItemNum>
            <OrderTotalPrice>{`$${
              !!showOrderTotal ? showOrderTotal : 0
            }`}</OrderTotalPrice>
          </BtnOrder>
        </BtnOrderWrapper>
      </Container>
      <ModalDiscount
        open={showDiscountModal}
        list={cartPrice.discountList}
        onClose={() => setShowDiscountModal(false)}
      />
    </>
  );
};

export default PriceSection;
