import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import _ from 'lodash';

import Modal from 'components/UI/Modal';
import Switch from 'components/UI/Switch/SwitchDefault';
import { Radio } from 'components/UI/Radio';
import ModalCreditCard from 'components/Cart/ModalCreditCard';

import { useCallbacks } from 'utils/callback';
import { useAPI } from 'utils/api';
import { md } from 'utils/breakpoints';
import { useLoading } from 'utils/loading';

import IconWallet from 'images/logo-s-square.svg';
import IconMastercard from 'images/i-mastercard.png';
import IconApplePay from 'images/Apple-Pay-Mark.png';
import IconLinepay from 'images/i-linepay.svg';
import IconJko from 'images/jkopay.png';
import IconBonusNotice from 'images/bonus-notice.svg';
import IconCreditCardArrow from 'images/credit-card-arrow.svg';
import { isSafari } from 'react-device-detect';
import {
  PaymentMethodCreditCard,
  PaymentMethodC4Wallet,
  PaymentMethodApplePay,
  PaymentMethodLinePay,
  PaymentMethodJkoPay,
  PaymentMethodPoint,
  PaymentMethodNone,
} from 'utils/constants/paymentMethod.js';

const Container = styled.div`
  width: 376px;
  height: 634px;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  padding: 16px 10px 120px;
  overflow: scroll;

  @media (max-width: ${md}) {
    width: 90vw;
    height: 80vh;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledModal = styled(Modal)``;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
  margin-bottom: 11px;
  text-align: center;
`;

const BonusWrapper = styled.div`
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  overflow: hidden;
`;

const BonusNotice = styled.div`
  background-color: #d7f4f6;
  font-size: 16px;
  font-weight: 700;
  color: #3b3516;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 0;

  > img {
    margin-right: 5px;
  }
`;

const BonusContentWrapper = styled.div`
  padding: 14px 10px;
`;

const BonusContentTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #333333;
`;

const BonusContent = styled.div`
  display: flex;
  align-items: center;
`;

const BonusContentBlock = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #333333;

  &:nth-child(2) {
    margin-right: 16px;
    margin-left: auto;
  }
`;

const InputBonus = styled.input`
  appearance: none;
  outline: none;
  width: 70px;
  height: 32px;
  border: 1px solid #ebeae8;
  text-align: right;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
`;

const PaymentOptionList = styled.div``;

const PaymentOption = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.credit-card {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }
`;

const CreditCardOptionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #e4e4e4;
  padding: 16px 0;
`;

const CreditCardOption = styled.div``;

const CreditCardOptionLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 10px;
`;

const CreditCardNum = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  display: flex;
  align-items: center;
`;

const CreditCardRemoveOption = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CreditCardRemove = styled.div`
  margin-top: 15px;
  font-size: 12px;
  font-weight: 400;
  color: #75a3f4;
  cursor: pointer;
`;

const CreditIcon = styled.img`
  margin-right: 8px;
`;

const AddCreditCardWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  cursor: pointer;
`;

const BtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 108px;
  background-color: #ffffff;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const Btn = styled.div`
  flex: 1;
  height: 52px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  line-height: 52px;
  cursor: pointer;
`;

const ConfirmBtn = styled(Btn)`
  background-color: #5fd2da;
  width: 90%;
  flex: 0 0 auto;
`;

const WalletNotice = styled.div`
  margin-top: 10px;
  padding: 0 10px;
  visibility: hidden;
  height: 0;

  ${(props) =>
    props.show &&
    css`
      visibility: visible;
      height: auto;
    `}
`;

const WalletNoticeRow = styled.div`
  display: flex;

  > div:nth-child(1) {
    flex: 0 0 20px;
  }
`;

const IconWalletWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 5px;
  color: #003e92;
  font-weight: 700;
`;

const ModalPayment = (props) => {
  const { onClose, open, setModalPayment } = props;
  const loading = useLoading();
  const callback = useCallbacks();
  const api = useAPI();
  const isPointValid = useRef(false);
  const cartPrice = useSelector((state) => getters.getCartPrice(state));
  const hkDiscountPrice = useSelector((state) =>
    getters.getHkDiscountPrice(state)
  );
  const info = useSelector((state) => getters.getInfo(state));
  const paymentMethods = useSelector((state) =>
    getters.getPaymentMethods(state)
  );
  const newCardInfo = useSelector((state) => getters.getNewCards(state));
  const creditInfo = useSelector((state) => getters.getCreditInfo(state));
  const paymentUpdate = useSelector((state) => getters.getPaymentUpdate(state));
  const payment = useSelector((state) => getters.getPayment(state));
  const [selectPayment, setSelectPayment] = useState(0);
  const [modalCreditCard, setModalCreditCard] = useState(false);
  const [limitPrice, setLimitPrice] = useState(0);
  const [canUsePrice, setCanUsePrice] = useState(0);
  const [usePrice, setUsePrice] = useState(0);
  const [totalBonus, setTotalBonus] = useState(0);
  const [usePointButtonState, setUsePointButtonState] = useState(false);
  const [showPaymentOption, setShowPaymentOption] = useState(true);
  const discount = useRef(0);
  const OPEN = 1;
  const CLOSE = 0;

  //檢查是否全額則底
  const checkAllPoint = (value) => {
    console.log('checkAllPoint value', value);
    console.log('checkAllPoint cartPrice', cartPrice);
    // const discount =
    //   cartPrice.discount - cartPrice.shippingTotal > 0
    //     ? cartPrice.discount - cartPrice.shippingTotal
    //     : 0;

    //如果紅利點數 === 商品總額 - 折扣 && 訂單總額 === 商品總額 - 折扣
    //運費不能被紅利折抵
    //訂單總額 === 商品總額 - 折扣 <- 如果有免運全額折抵才會成立
    if (
      Number.parseInt(value) === cartPrice.productTotal - discount.current &&
      cartPrice.orderTotal === cartPrice.productTotal - discount.current
    ) {
      console.log('checkAllPoint true');
      setSelectPayment(24);
      setShowPaymentOption(false);
    } else {
      console.log('checkAllPoint false');
      setSelectPayment(
        selectPayment === PaymentMethodPoint ? 0 : selectPayment
      );
      setShowPaymentOption(true);
    }
  };

  const onSwitchPointUseButton = (e) => {
    setUsePointButtonState(e.target.checked);
    if (e.target.checked) {
      setUsePrice(canUsePrice);
      checkAllPoint(canUsePrice);
    } else {
      setUsePrice(0);
      checkAllPoint(0);
    }
  };

  const onChangePriceValue = (event) => {
    console.log('onChangePriceValue', event.target.value);
    console.log('onChangePriceValue canUsePrice', canUsePrice);
    const maxPrice = canUsePrice || 0;
    const currentPrice = Number(event.target.value);
    if (!/^\d*$/.test(currentPrice)) return;
    console.log('onChangePriceValue maxPrice', maxPrice);
    let value = 0;
    value = currentPrice > maxPrice ? maxPrice : currentPrice;
    setUsePrice(value);
    checkAllPoint(value); //檢查是否全額
  };

  const openModalCreditCard = () => {
    setModalCreditCard(true);
  };

  const closeModalCreditCard = () => {
    setModalCreditCard(false);
  };

  const editCard = (mode, paymentSelect) => {
    if (mode === 'add') {
      console.log('addCard paymentSelect', paymentSelect);
      setSelectPayment(paymentSelect);
    }
    closeModalCreditCard();
    setModalPayment(true);
  };

  const handlePointFormula = (point) => {
    console.log('handlePointFormula point', point);
    console.log('handlePointFormula info', info);
    console.log('handlePointFormula cartPrice', cartPrice);
    const totalBonus = Math.floor(point / info.cart.clpeRatio);
    setTotalBonus(totalBonus);

    console.log('handlePointFormula totalBonus', totalBonus);
    console.log('handlePointFormula totalBonus', hkDiscountPrice);
    console.log(
      'handlePointFormula setCanUsePrice',
      totalBonus <= hkDiscountPrice ? totalBonus : hkDiscountPrice
    );
    setCanUsePrice(
      totalBonus <= hkDiscountPrice ? totalBonus : hkDiscountPrice
    );
  };

  const onCheangePaymentSection = (value) => {
    setSelectPayment(value);
  };

  const onChangePayment = (event) => {
    setSelectPayment(Number(event.target.value));
    //ApplePay
  };

  useEffect(() => {
    const func = {
      paymentEditCard: editCard,
    };
    callback.setCallback(func);

    //折扣折掉運費之後是否還有剩下
    discount.current =
      cartPrice.discount - cartPrice.shippingTotal > 0
        ? cartPrice.discount - cartPrice.shippingTotal
        : 0;

    const bonusThreshold = _.get(info, 'cart.bonusThreshold', 0);
    const productTotal = _.get(cartPrice, 'productTotal', 0);
    console.log(
      'bonusThreshold - (productTotal - discount.current)',
      bonusThreshold - (productTotal - discount.current)
    );
    setLimitPrice(
      productTotal - discount.current - bonusThreshold < 0
        ? bonusThreshold - (productTotal - discount.current)
        : 0
    );

    //判斷上次使用的狀態
    if (payment?.clpe === undefined || payment?.clpe.value === 0) {
      setUsePointButtonState(false);
      setUsePrice(0);
    } else {
      setUsePointButtonState(true);
      setUsePrice(payment.clpe.value);
      checkAllPoint(payment.clpe.value);
    }

    //取得紅利點數
    loading.show();
    api
      .getBonusPoint()
      .then((res) => {
        loading.dismiss();
        if (!res) return;
        isPointValid.current = res?.valid;
        const point = res.point;
        handlePointFormula(point);
        if (!isPointValid.current) {
          res?.validreason !== ''
            ? alert(res?.validreason)
            : alert('系統錯誤，請聯絡客服。');
        }
      })
      .catch(() => {
        loading.dismiss();
      });
  }, []);

  useEffect(() => {
    if (payment.payment) {
      if (
        payment.payment === PaymentMethodCreditCard &&
        payment.cardInfo &&
        payment.cardInfo.isNewCard
      ) {
        var findIndex = 0;
        if (newCardInfo.length > 0) {
          const result = newCardInfo.find((item) => {
            var expire = item.expire;
            expire = expire.split('/');
            const expireMM = expire[0].replace(' ', '');
            const expireYY = expire[1].replace(' ', '');
            return (
              item.cardNo === payment.cardInfo.cardNo &&
              item.cvc === payment.cardInfo.cvc &&
              `20${expireYY}${expireMM}` === payment.cardInfo.expire
            );
          });

          findIndex = newCardInfo.indexOf(result);
        }

        setSelectPayment(1000 + findIndex);
      } else {
        setSelectPayment(payment.payment);
      }
    }
  }, [payment]);

  const back2Cart = () => {
    if (selectPayment === 0) {
      alert('請選擇付款方式');
      return;
    }

    var saveData = {};
    if (selectPayment >= 1000) {
      const creditSelect = selectPayment - 1000;
      var expire = newCardInfo[creditSelect].expire;
      expire = expire.split('/');
      const expireMM = expire[0].replace(' ', '');
      const expireYY = expire[1].replace(' ', '');
      saveData = {
        ...payment,
        payment: PaymentMethodCreditCard,
        cardInfo: {
          cardNo: newCardInfo[creditSelect].cardNo,
          cvc: newCardInfo[creditSelect].cvc,
          expire: `20${expireYY}${expireMM}`,
          isNewCard: true,
          checked: newCardInfo[creditSelect].checked,
        },
      };
    } else if (selectPayment === PaymentMethodCreditCard) {
      saveData = {
        ...payment,
        payment: selectPayment,
        cardInfo: {
          cardNo: creditInfo.creditCardNumber ?? '',
          cvc: '',
          expire: `${creditInfo.creditCardExpirationYear ?? ''}${
            creditInfo.creditCardExpirationMonth ?? ''
          }`,
          isNewCard: false,
        },
      };
    } else {
      saveData = {
        ...payment,
        payment: selectPayment,
      };
    }

    if (usePointButtonState && usePrice < 0) {
      alert('紅利輸入錯誤');
      return;
    }

    saveData =
      usePointButtonState &&
      usePrice !== undefined &&
      usePrice !== '' &&
      usePrice !== 0 &&
      !Number.isNaN(usePrice)
        ? {
            ...saveData,
            clpe: {
              point: usePrice * 300,
              value: Number.parseInt(usePrice),
            },
          }
        : {
            ...saveData,
            clpe: {
              point: 0,
              value: 0,
            },
          };
    store.dispatch(actions.setPayment(saveData));

    setModalPayment(false);
  };

  const removeCreditCard = () => {
    console.log('removeCreditCard');
    api
      .removeCreditCard()
      .then((res) => {
        store.dispatch(actions.setCreditInfo({}));
        store.dispatch(actions.setPaymentUpdate(true));
        localStorage.setItem(
          'KeepPaymentType',
          JSON.stringify({ payment: PaymentMethodNone })
        );
        const saveData = {
          ...payment,
          payment: PaymentMethodNone,
        };
        setSelectPayment(PaymentMethodNone);
        store.dispatch(actions.setPayment(saveData));
      })
      .catch((err) => {});
  };
  return (
    <>
      <StyledModal open={open} onClose={() => back2Cart()}>
        {!modalCreditCard && (
          <Container>
            <Title>付款方式</Title>
            <BonusWrapper>
              <BonusNotice>
                <img src={IconBonusNotice} />
                尚差${limitPrice}可使用紅利折抵
              </BonusNotice>
              <BonusContentWrapper>
                <BonusContentTitle>點數折抵</BonusContentTitle>
                <BonusContent>
                  <BonusContentBlock>可折抵 {totalBonus} 元</BonusContentBlock>
                  <BonusContentBlock>
                    <InputBonus
                      value={usePrice ? usePrice : ''}
                      disabled={limitPrice > 0 || !usePointButtonState}
                      onChange={onChangePriceValue}
                    />
                    元
                  </BonusContentBlock>
                  <BonusContentBlock>
                    <Switch
                      onChange={onSwitchPointUseButton}
                      checked={usePointButtonState}
                      disabled={
                        limitPrice > 0 ||
                        canUsePrice === 0 ||
                        !isPointValid.current
                      }
                    />
                  </BonusContentBlock>
                </BonusContent>
              </BonusContentWrapper>
            </BonusWrapper>
            {showPaymentOption && (
              <PaymentOptionList>
                {paymentMethods?.payByWallet === OPEN && (
                  <PaymentOption onClick={() => onCheangePaymentSection(20)}>
                    <IconWalletWrapper>
                      <img src={IconWallet} alt="wallet" />
                      家樂福錢包
                    </IconWalletWrapper>
                    <Radio
                      name="payment-option"
                      value={20}
                      defaultValue={selectPayment}
                      onChange={onChangePayment}
                    />
                  </PaymentOption>
                )}
                {paymentMethods?.payByCreditCard === OPEN && (
                  <>
                    {creditInfo &&
                      Object.keys(creditInfo).length !== 0 &&
                      creditInfo.creditCardNumber !== '' && (
                        <>
                          <PaymentOption
                            className="credit-card"
                            onClick={() =>
                              onCheangePaymentSection(PaymentMethodCreditCard)
                            }
                          >
                            <CreditCardOptionWrapper>
                              <CreditCardOption>
                                <CreditCardOptionLabel>
                                  信用卡
                                </CreditCardOptionLabel>

                                <CreditCardNum>
                                  <CreditIcon
                                    src={IconMastercard}
                                    alt="credit"
                                    width={24}
                                  />
                                  {creditInfo.creditCardNumber}
                                </CreditCardNum>
                              </CreditCardOption>
                              <CreditCardOption>
                                <CreditCardRemoveOption>
                                  <Radio
                                    defaultValue={selectPayment}
                                    name="payment-option"
                                    value={PaymentMethodCreditCard}
                                    onChange={onChangePayment}
                                  />
                                </CreditCardRemoveOption>
                                <CreditCardRemove
                                  onClick={() => removeCreditCard()}
                                >
                                  刪除
                                </CreditCardRemove>
                              </CreditCardOption>
                            </CreditCardOptionWrapper>
                          </PaymentOption>
                          <PaymentOption>
                            <div>卡片到期日</div>
                            <div>{`${
                              creditInfo.creditCardExpirationMonth
                            }/${creditInfo.creditCardExpirationYear.substring(
                              creditInfo.creditCardExpirationYear.length - 2
                            )}`}</div>
                          </PaymentOption>
                        </>
                      )}
                  </>
                )}

                {newCardInfo.map((card, index) => (
                  <PaymentOption
                    className="credit-card"
                    key={`credit-card-${index}`}
                    onClick={() => onCheangePaymentSection(1000 + index)}
                  >
                    <CreditCardOptionWrapper>
                      <CreditCardOption>
                        <CreditCardOptionLabel>信用卡</CreditCardOptionLabel>
                        <CreditCardNum>
                          <CreditIcon
                            src={IconMastercard}
                            alt="credit"
                            width={24}
                          />
                          {card.cardNo}
                        </CreditCardNum>
                      </CreditCardOption>
                      <CreditCardOption>
                        <CreditCardRemoveOption>
                          <Radio
                            name="payment-option"
                            value={1000 + index}
                            defaultValue={selectPayment}
                            onChange={onChangePayment}
                          />
                        </CreditCardRemoveOption>
                      </CreditCardOption>
                    </CreditCardOptionWrapper>
                  </PaymentOption>
                ))}
                <PaymentOption className="credit-card">
                  <AddCreditCardWrapper onClick={openModalCreditCard}>
                    <div>
                      {creditInfo &&
                      creditInfo?.creditCardNumber !== '' &&
                      Object.keys(creditInfo).length !== 0
                        ? '更換信用卡'
                        : '信用卡支付'}
                    </div>
                    <img src={IconCreditCardArrow} />
                  </AddCreditCardWrapper>
                </PaymentOption>
                {isSafari && paymentMethods?.payByApplePay === OPEN && (
                  <PaymentOption
                    onClick={() =>
                      onCheangePaymentSection(PaymentMethodApplePay)
                    }
                  >
                    <img src={IconApplePay} alt="apple-pay" width={50} />
                    <Radio
                      id="payment-method-applepay"
                      name="payment-option"
                      value={PaymentMethodApplePay}
                      defaultValue={selectPayment}
                      onChange={onChangePayment}
                    />
                  </PaymentOption>
                )}
                {paymentMethods?.payByLinePay === OPEN && (
                  <PaymentOption
                    onClick={() =>
                      onCheangePaymentSection(PaymentMethodLinePay)
                    }
                  >
                    <img src={IconLinepay} alt="linepay" width={61} />
                    <Radio
                      id="payment-method-linepay"
                      name="payment-option"
                      value={PaymentMethodLinePay}
                      defaultValue={selectPayment}
                      onChange={onChangePayment}
                    />
                  </PaymentOption>
                )}
                {paymentMethods?.payByJkoPay === OPEN && (
                  <PaymentOption
                    onClick={() => onCheangePaymentSection(PaymentMethodJkoPay)}
                  >
                    <img src={IconJko} alt="jko" width={70} />
                    <Radio
                      id="payment-method-jkopay"
                      name="payment-option"
                      value={PaymentMethodJkoPay}
                      defaultValue={selectPayment}
                      onChange={onChangePayment}
                    />
                  </PaymentOption>
                )}
              </PaymentOptionList>
            )}
            <WalletNotice
              show={
                selectPayment === PaymentMethodC4Wallet || usePointButtonState
              }
            >
              <WalletNoticeRow>
                <div>1.</div>
                <div>
                  <span style={{ color: 'red' }}>
                    若使用紅利點數或家樂福錢包，則折抵後金額為0元，將不開立發票。
                  </span>
                  若您有報帳等需求，請選擇其他支付方式。
                </div>
              </WalletNoticeRow>
              <WalletNoticeRow>
                <div>2.</div>
                <div>
                  家樂福錢包付款是使用事先儲值進家樂福錢包的餘額進行付款。如欲使用請先確認已於APP開通錢包功能。(暫未開放CarrefourPay刷卡付款)
                </div>
              </WalletNoticeRow>
              <WalletNoticeRow>
                <div>3.</div>
                <div>
                  家樂福錢包付款之金額，也可累積會員點數。家樂福錢包之優惠，線上購物與實體門市部份優惠活動不相同，請以活動公告為主。
                </div>
              </WalletNoticeRow>
              <WalletNoticeRow>
                <div>4.</div>
                <div>
                  家樂福錢包密碼若連續5次輸入錯誤，系統會自動鎖住，請重設密碼。
                </div>
              </WalletNoticeRow>
              <WalletNoticeRow>
                <div>5.</div>
                <div>
                  使用家樂福錢包消費後，若訂單取消或至原出貨店完成退貨流程後，原消費款項將立即退至家樂福錢包中；若至非出貨店退貨，則退回實體禮物卡。恕無法提供現金或其他方式退回，原若使用好康卡或家樂福信用卡所獲之紅利點數及其他優惠，亦將一併扣除。
                </div>
              </WalletNoticeRow>
            </WalletNotice>
            <BtnGroup>
              <ConfirmBtn onClick={() => back2Cart()}>確認</ConfirmBtn>
            </BtnGroup>
          </Container>
        )}
      </StyledModal>
      <ModalCreditCard
        open={modalCreditCard}
        onClose={closeModalCreditCard}
        setModalCreditCard={setModalCreditCard}
      />
    </>
  );
};

export default ModalPayment;
